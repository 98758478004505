import {
  Component,
  Inject,
  Input,
  ViewEncapsulation,
  ViewChild,
  EventEmitter,
  Output,
} from "@angular/core";
import { BaseComponent } from "../../base-component";
import {
  DeviceMonitoringService,
  MAX_BUZZER_SOUND,
  MIN_BUZZER_SOUND,
} from "../../ui.module";
import { Room, RoomsService } from "../../rooms.service";
import {
  ConfigService,
  MeasurementUnits,
  isInLearningMode,
  SensorMounting,
} from "../../config.service";
import { RoomTypeMapping } from "../../device-icon";
import {
  DeviceInfo,
  RoomType,
  TrackerSubRegion,
  Suite,
  DeviceStateGen2,
  DeviceConfigGen2,
  LedPolicy,
} from "@walabot-mqtt-dashboard/api";
import {
  FormGroup,
  FormArray,
  FormControl,
  UntypedFormBuilder,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { takeUntil } from "rxjs/operators";
import { Environment } from "../../models";
import {
  ROOM_LIMITS_IN_METERS,
  SENSOR_HEIGHT_MAX_DIGITS_AFTER_DECIMAL_POINT,
} from "./room-size-limit";
import {
  convertFeetAndInchesToMeters,
  convertMetersToFeet,
  getLearningModeMessage,
} from "./device-settings-utils";

import {
  RoomSizeForm,
  DeviceConfig,
  RoomSizeFromForm,
} from "./device-settings-interface";
import { SubRegionsComponent } from "./sub-regions/sub-regions.component";
import { RoomSizeComponent } from "./room-size/room-size.component";
import { GeneralDetailsComponent } from "./general-details/general-details.component";
import {
  DialogComponent,
  DialogData,
  DialogType,
} from "../../dialog/dialog.component";
import * as ioTs from "io-ts";
import { isLeft } from "fp-ts/Either";
import { isEqual } from "lodash";

const DeviceConfigurationIoTs = ioTs.type({
  appConfig: ioTs.type({
    volume: ioTs.number,
    ledPolicy: ioTs.string,
    silentMode: ioTs.boolean,
    enableTestMode: ioTs.boolean,
  }),
  walabotConfig: ioTs.type({
    xMin: ioTs.number,
    xMax: ioTs.number,
    yMin: ioTs.number,
    yMax: ioTs.number,
    zMin: ioTs.number,
    zMax: ioTs.number,
    enterDuration: ioTs.number,
    exitDuration: ioTs.number,
    sensorHeight: ioTs.number,
  }),
});

enum TABS {
  GENERAL_DETAILS = 1,
  ROOM_SIZE = 2,
  SUB_REGIONS = 3,
}

@Component({
  selector: "app-device-settings",
  templateUrl: "./device-settings.component.html",
  styleUrls: ["./device-settings.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class DeviceSettingsComponent extends BaseComponent {
  @Input() validateRoomName: (roomName: string) => boolean;
  @ViewChild(SubRegionsComponent) subregionComponent: SubRegionsComponent;
  @ViewChild(RoomSizeComponent) roomSizeComponent: RoomSizeComponent;
  @ViewChild(GeneralDetailsComponent)
  generalDetailsComponent: GeneralDetailsComponent;
  @Output() back = new EventEmitter();
  @Output() assign = new EventEmitter();
  controlLastUpdateTimestamp = {
    // room
    roomXMin: 0,
    roomXMax: 0,
    roomYMin: 0,
    roomYMax: 0,
    // subregion
    xMin: 0,
    xMax: 0,
    yMin: 0,
    yMax: 0,
    zMin: 0,
    zMax: 0,
  };
  get deviceSettings() {
    return this.configurationForm.get("deviceSettingsForm") as FormGroup;
  }

  get roomSize() {
    return this.configurationForm.get(
      "roomSizeForm"
    ) as FormGroup<RoomSizeForm>;
  }

  get subRegions() {
    return this.configurationForm.get("subRegionsForm") as FormArray<FormGroup>;
  }

  get roomType(): FormControl<RoomType> {
    return this.deviceSettings.get("roomType") as FormControl<RoomType>;
  }

  get roomName(): FormControl<string> {
    return this.deviceSettings.get("roomName") as FormControl<string>;
  }

  set device(device: DeviceConfig) {
    this.config = null;
    this._device = device;
    this.configService
      .getConfig(device.deviceId)
      .then((config) => {
        if (this.isHaveErrorDeviceConfig(config)) {
          return this.showErrorMsg(
            $localize`:@@device-configuration-data-is-incorrect:The device configuration data is incorrect`
          );
        }
        this.config = config;

        this.configService
          .getDashboardConfig()
          .pipe(takeUntil(this.ngUnsubsrcibe))
          .subscribe((dashboardConfig) => {
            this.unit = dashboardConfig
              ? dashboardConfig.measurementUnits
              : MeasurementUnits.Metric;
          });
      })
      .catch((error) => console.error("Unable to get config: ", error));
    this.deviceService
      .getDeviceInfo(device.deviceId)
      .then((deviceInfo) => {
        this.deviceInfo = deviceInfo;
      })
      .catch((err) => console.warn(err));

    this.deviceService.deviceStateMap
      .pipe(takeUntil(this.ngUnsubsrcibe))
      .subscribe((states) => {
        if (states && states[device.deviceId]) {
          this.lastSeen = states[device.deviceId].updateTimestamp;
          this.wifiState = states[device.deviceId].state.wifiState;
        }
      });
  }

  room: Room;
  suite: Suite;
  inProgress: boolean;
  config: DeviceConfigGen2;
  _device: DeviceConfig;
  deviceInfo: DeviceInfo;
  lastSeen: number;
  wifiState: DeviceStateGen2["wifiState"];
  unit: MeasurementUnits;
  configurationForm = this.fb.group({
    subRegionsForm: this.fb.array([]),
  });

  learningModePeriodMillis: number;
  selectedTabIndex = TABS.GENERAL_DETAILS;

  drawSubRegionEventEmitter = new EventEmitter();
  is3DViewSelected = false;
  inE2ETest: boolean = "Cypress" in window;

  constructor(
    private deviceService: DeviceMonitoringService,
    private configService: ConfigService,
    private roomsService: RoomsService,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    @Inject("environment") private environment: { [key: string]: Environment }
  ) {
    super();
    this.learningModePeriodMillis = this.environment
      .learningModeDuration as number;
  }

  isHaveErrorDeviceConfig(config: DeviceConfigGen2) {
    const decode = DeviceConfigurationIoTs.decode(config);
    const isHaveError = isLeft(decode);
    isHaveError && console.warn("DeviceConfiguration Have Errors: ", decode);
    return isHaveError;
  }

  isMetric() {
    return this.unit === MeasurementUnits.Metric;
  }

  isWallMount() {
    const sensorMountVal =
      this.configurationForm && this.deviceSettings.get("sensorMounting")
        ? (this.deviceSettings.get("sensorMounting").value as number)
        : 0;
    return (
      sensorMountVal === SensorMounting.Wall ||
      sensorMountVal === SensorMounting.Tilt45Deg ||
      sensorMountVal == null
    );
  }

  getDeviceName(type: RoomType) {
    return RoomTypeMapping.get(parseInt(String(type), 10)).name;
  }

  reset(deviceName: string, type: RoomType) {
    const message = $localize`:@@reset-device-message:You are going to reset ${deviceName}:name: device.<br/>
    All the unsaved changes will be discarded.<br/>
    Are you sure you want to reset ${deviceName}:name: device?`;
    const title = $localize`:@@reset-device-title:Reset ${deviceName}:name: device`;

    const okBtnText = $localize`:@@reset-device:Reset Device`;
    const cancelBtnText = $localize`:@@cancel:Cancel`;

    const data: DialogData = {
      title,
      message,
      typeDialog: DialogType.MESSAGE,
      okBtnText,
      cancelBtnText,
      btnHandler: (dialog, confirmed) => {
        if (!confirmed) {
          dialog.close();
        } else {
          this.generalDetailsComponent.setFormData(type);
          this.roomSizeComponent.setFormData();
          this.subregionComponent.setFormData();
          dialog.close();
        }
      },
    };
    this.dialog.open(DialogComponent, {
      panelClass: "warning-dialog",
      data,
    });
  }

  /**
   * When changing the device mounting type, if the room size form has an error,
   * we show a popup window. The buttons in the popup window behave as follows:
   * "Cancel" - close the pop-up window and restore previous value
   * "OK" - close the pop-up window and go to the room tab
   */
  validateRoomSizeAgainstSensorMount() {
    let previousYMin: number;
    const dialogCallback = (confirmed) => {
      if (this.isWallMount() && !confirmed) {
        this.config.walabotConfig.yMin = previousYMin;
        this.subRegions.controls.forEach((el) => {
          this.updateValidityStateForFields(el);
        });
      }
    };
    if (this.isWallMount()) {
      previousYMin = this.config.walabotConfig.yMin;
      this.config.walabotConfig.yMin = ROOM_LIMITS_IN_METERS.lengthMin;
      this.roomSize
        .get("roomCeilingHeight")
        .setValue(
          +ROOM_LIMITS_IN_METERS.standardHeight.toFixed(
            SENSOR_HEIGHT_MAX_DIGITS_AFTER_DECIMAL_POINT
          )
        );
      const heightFeet = convertMetersToFeet(
        ROOM_LIMITS_IN_METERS.standardHeight
      );
      this.roomSize.get("roomCeilingHeightFeet").setValue(heightFeet[0]);
      this.roomSize.get("roomCeilingHeightInches").setValue(heightFeet[1]);
      this.subRegions.controls.forEach((el) => {
        this.updateValidityStateForFields(el);
      });
    }
    if (this.roomSize.errors) {
      const message = $localize`:@@room-size-invalid-message:Please go to "ROOM SIZE" tab, and correct the invalid values`;
      const title = $localize`:@@room-size-invalid-title:Room size values are invalid `;
      this.showWarningMessageAndChangeTabIfNeeded(
        title,
        message,
        TABS.ROOM_SIZE
      )
        .then(dialogCallback)
        .catch((e) => {
          console.error(e);
        });
    } else if (this.subRegions.controls.some((control) => control.errors)) {
      const message = $localize`:@@subregion-size-invalid-message:Please go to "SUB REGION" tab, and correct the invalid values`;
      const title = $localize`:@@subregion-size-invalid:Sub region values are invalid `;
      this.showWarningMessageAndChangeTabIfNeeded(
        title,
        message,
        TABS.SUB_REGIONS
      )
        .then(dialogCallback)
        .catch((e) => {
          console.error(e);
        });
    }
  }

  public saveConfiguration() {
    let configData = {
      appConfig: {},
      walabotConfig: {},
    } as DeviceConfigGen2;
    // app config
    if (
      this.config.appConfig.silentMode !==
      (this.deviceSettings.get("silentMode").value as boolean)
    ) {
      configData.appConfig.silentMode = this.deviceSettings.get("silentMode")
        .value as boolean;
    }

    const ledPolicyValue = this.deviceSettings.get("ledLight")
      .value as LedPolicy;
    if (this.config.appConfig.ledPolicy !== ledPolicyValue) {
      configData.appConfig.ledPolicy = ledPolicyValue;
    }

    if (
      this.config.appConfig.enableTestMode !==
      this.deviceSettings.get("testMode").value
    ) {
      configData.appConfig.enableTestMode = this.deviceSettings.get("testMode")
        .value as boolean;
    }

    if (
      this.config.walabotConfig.fallingMitigatorEnabled !==
      this.deviceSettings.get("fallingMitigatorEnabled").value
    ) {
      configData.walabotConfig.fallingMitigatorEnabled =
        this.deviceSettings.get("fallingMitigatorEnabled").value as boolean;
    }

    if (
      this.config.appConfig.enableDoorEvents !==
      this.deviceSettings.get("enableDoorEvents").value
    ) {
      configData.appConfig.enableDoorEvents = this.deviceSettings.get(
        "enableDoorEvents"
      ).value as boolean;
    }

    if (
      this.config.appConfig.enableOutOfBed !==
      this.deviceSettings.get("enableOutOfBed").value
    ) {
      configData.appConfig.enableOutOfBed = this.deviceSettings.get(
        "enableOutOfBed"
      ).value as boolean;
    }

    const buzzerSoundValue = this.deviceSettings.get("buzzerSound").value
      ? MAX_BUZZER_SOUND
      : MIN_BUZZER_SOUND;
    if (this.config.appConfig.volume !== buzzerSoundValue) {
      configData.appConfig.volume = buzzerSoundValue;
    }

    const learningMode = this.deviceSettings.get("learningMode");
    if (learningMode.touched) {
      const wasLearning = isInLearningMode(this.config.appConfig);
      if (wasLearning && !learningMode.value) {
        configData.appConfig.learningModeStartTs = 0;
        configData.appConfig.learningModeEndTs = 0;
      } else if (!wasLearning && learningMode.value) {
        configData.appConfig.learningModeStartTs = Date.now();
        configData.appConfig.learningModeEndTs =
          Date.now() + this.learningModePeriodMillis;
      }
    }

    const dryContactPrimaryPolicyValue = +this.deviceSettings.get(
      "dryContactPrimaryPolicy"
    ).value;
    const dryContactPrimaryModeValue = +this.deviceSettings.get(
      "dryContactPrimaryMode"
    ).value;

    const dryContactSecondaryPolicyValue = +this.deviceSettings.get(
      "dryContactSecondaryPolicy"
    ).value;

    const dryContactSecondaryModeValue = +this.deviceSettings.get(
      "dryContactSecondaryMode"
    ).value;

    const dryContacts = this.config.appConfig.dryContacts;

    if (
      dryContacts.primary.policy !== dryContactPrimaryPolicyValue ||
      dryContacts.primary.mode !== dryContactPrimaryModeValue ||
      dryContacts.secondary.policy !== dryContactSecondaryPolicyValue ||
      dryContacts.secondary.mode !== dryContactSecondaryModeValue
    ) {
      configData.appConfig.dryContacts = {
        primary: {
          policy: dryContactPrimaryPolicyValue,
          mode: dryContactPrimaryModeValue,
        },
        secondary: {
          policy: dryContactSecondaryPolicyValue,
          mode: dryContactSecondaryModeValue,
        },
      };
    }

    const sensorMountingValue = parseInt(
      this.deviceSettings.get("sensorMounting").value as string,
      10
    );

    if (this.config.walabotConfig.sensorMounting !== sensorMountingValue) {
      configData.walabotConfig.sensorMounting = sensorMountingValue;
      if (
        [SensorMounting.Wall, SensorMounting.Tilt45Deg].includes(
          sensorMountingValue
        )
      ) {
        configData.walabotConfig.yMin = this.config.walabotConfig.yMin;
      }
    }

    if (
      this.config.walabotConfig.sensorHeight !== this.getSensorHeightFromForm()
    ) {
      configData.walabotConfig.sensorHeight = this.getSensorHeightFromForm();
    }

    // walabot config
    this.addChangedWalabotConfig(
      "enterDuration",
      this.deviceSettings.get("enterDuration").value,
      configData
    );

    this.addChangedWalabotConfig(
      "exitDuration",
      this.deviceSettings.get("exitDuration").value,
      configData
    );

    this.addChangedWalabotConfig(
      "xMin",
      this.getRoomSizeFromForm().xMin,
      configData
    );

    this.addChangedWalabotConfig(
      "xMax",
      this.getRoomSizeFromForm().xMax,
      configData
    );

    this.addChangedWalabotConfig(
      "yMin",
      this.getRoomSizeFromForm().yMin,
      configData
    );

    this.addChangedWalabotConfig(
      "yMax",
      this.getRoomSizeFromForm().yMax,
      configData
    );
    // subregions
    configData.walabotConfig.trackerSubRegions = this.getSubregionsFromForm();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    const subRegionIsEqual: boolean = isEqual(
      this.config.walabotConfig.trackerSubRegions,
      configData.walabotConfig.trackerSubRegions
    );
    if (subRegionIsEqual) {
      delete configData.walabotConfig.trackerSubRegions;
    }
    configData = this.removeEmptyConfig(configData) as DeviceConfigGen2;
    const promises = [];
    let isChangeConfig = false;
    let isChangeRoomType = false;

    if (!this.isEmpty(configData)) {
      isChangeConfig = true;
      promises.push(
        this.configService.sendConfig(this._device.deviceId, configData)
      );
    }

    if (this._device.type !== this.roomType.value) {
      const deviceTypeValueType = typeof this._device.type;
      const roomTypeValueType = typeof this.roomType.value;
      if (deviceTypeValueType === roomTypeValueType) {
        isChangeRoomType = true;
        console.log(
          `Updating roomType from ${this._device.type} to ${this.roomType.value}`
        );
        promises.push(
          this.roomsService.changeDeviceTypeAndDeviceRoomType(
            this._device.deviceId,
            this._device.roomId,
            +this.roomType.value,
            this.roomName.value
          )
        );
      } else {
        console.error(
          `The types of device.type (${deviceTypeValueType}, ${this._device.type}) and roomType.value (${roomTypeValueType}, ${this.roomType.value}) are different!`
        );
        const errorMessage = `The types of device and room are different!`;
        this.showErrorMsg(
          $localize`:@@data-error-message:Data Error: ${errorMessage}:errorMessage:`
        );
        return;
      }
    } else if (this.room && this.roomName.value !== this.room.name) {
      promises.push(
        this.roomsService.updateRoom(
          this._device.roomId,
          this.roomName.value,
          +this.roomType.value
        )
      );
    }
    const deviceId = this._device.deviceId;
    this.inProgress = true;
    Promise.all(promises)
      .then((response) => {
        this.inProgress = false;
        this.showSuccessMsg(
          $localize`:@@device-configuration-saved-successfully:Device configuration saved successfully`
        );
        // Check that user didn't select another device before request finished
        if (deviceId === this._device.deviceId) {
          this.configurationForm.markAsPristine();
          if (isChangeConfig) {
            this.config = response[0] as DeviceConfigGen2;
          }
          if (isChangeRoomType) {
            let index = 0;
            if (isChangeConfig) {
              index = 1;
            }

            this._device.type = (response[index] as Room).type;
            this.room.type = (response[index] as Room).type;
            this.room.name = (response[index] as Room).name;
          }
        }
      })
      .catch(() => {
        this.inProgress = false;
        this.showErrorMsg(
          $localize`:@@failed-to-save-device-configuration:Failed to save device configuration`
        );
      });
  }

  removeEmptyConfig(obj: Record<string, unknown>) {
    console.log("removeEmptyConfig", JSON.stringify(obj));
    return Object.keys(obj)
      .filter((key) => {
        return !this.isEmpty(obj[key]);
      })
      .reduce((acc, key) => {
        if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
          acc[key] = this.removeEmptyConfig(
            obj[key] as Record<string, unknown>
          );
        } else {
          acc[key] = obj[key];
        }
        return acc;
      }, {});
  }

  isEmpty(objectName: unknown) {
    return (
      Object.keys(objectName).length === 0 && objectName.constructor === Object
    );
  }

  addChangedWalabotConfig(
    fieldName: string,
    value,
    configData: DeviceConfigGen2
  ) {
    if (fieldName === "yMax") {
      console.log(value, this.config.walabotConfig[fieldName]);
    }
    if (this.config.walabotConfig[fieldName] !== value) {
      configData.walabotConfig[fieldName] = value as number;
    }
  }

  showSuccessMsg(msg: string) {
    this.snackBar.open(msg, "X", {
      duration: 3000,
      horizontalPosition: "left",
      panelClass: "success-msg",
    });
  }

  showErrorMsg(msg: string) {
    this.snackBar.open(msg, "X", {
      duration: 3000,
      horizontalPosition: "left",
      panelClass: ["success-msg", "error"],
    });
  }

  isLearning(): boolean {
    return isInLearningMode(this.config.appConfig);
  }

  getLearningModeStr() {
    const timeDiffMillis = this.config.appConfig.learningModeEndTs - Date.now();
    return getLearningModeMessage(timeDiffMillis);
  }

  onTabChanged(event: MatTabChangeEvent) {
    switch (event.index) {
      case TABS.GENERAL_DETAILS:
        this.updateValidityStateForFields(this.deviceSettings);
        break;
      case TABS.ROOM_SIZE:
        this.updateValidityStateForFields(this.roomSize);
        break;
      case TABS.SUB_REGIONS:
        this.subRegions.controls.forEach((el) => {
          this.updateValidityStateForFields(el);
        });
        break;
    }
  }

  validateRoomSizeAgainstSubregionChanged(controlName: string) {
    this.updateLastUpdateTimestamp(controlName);
    this.updateValidityStateForFields(this.roomSize);
  }

  updateLastUpdateTimestamp(controlName: string) {
    this.controlLastUpdateTimestamp[controlName] = +new Date();
  }

  validateSubregionSizeAgainstRoomChanged(controlName: string) {
    this.updateLastUpdateTimestamp(controlName);
    this.subRegions.controls.forEach((el) => {
      this.updateValidityStateForFields(el);
    });
  }

  updateValidityStateForFields(form: FormGroup) {
    Object.keys(form.controls).forEach((controlName) => {
      const control = form.get(controlName);
      control.updateValueAndValidity();
    });
  }

  getTooltipInvalidData() {
    return $localize`:@@tab-invalid-data:Tab contains invalid data`;
  }

  getCountOfInvalidFields(form: FormArray | FormGroup) {
    if (Array.isArray(form.controls)) {
      let count = 0;
      form.controls.forEach((el: FormGroup) => {
        count += this.countErrors(el);
      });
      return count;
    } else {
      return this.countErrors(form as FormGroup);
    }
  }

  addSubRegion(region: TrackerSubRegion) {
    this.subregionComponent.addSubRegion(region);
    this.configurationForm.markAsDirty();
  }

  private countErrors(form: FormGroup) {
    let count = 0;
    if (form.errors) {
      count += Object.values(form.errors).length;
    }
    for (const controlKey in form.controls) {
      if (Object.prototype.hasOwnProperty.call(form.controls, controlKey)) {
        if (form.controls[controlKey].errors) {
          count += Object.keys(form.controls[controlKey].errors).length;
        }
      }
    }
    return count;
  }

  getRoomSizeFromForm() {
    const roomSize = {
      zMin: this.config.walabotConfig.zMin,
      zMax: this.config.walabotConfig.zMax,
    } as RoomSizeFromForm;

    // walabot config
    if (this.isMetric()) {
      roomSize.xMin = this.roomSize.get("roomXMin").value;
      roomSize.xMax = this.roomSize.get("roomXMax").value;
      roomSize.yMin = this.roomSize.get("roomYMin").value;
      roomSize.yMax = this.roomSize.get("roomYMax").value;
      if (this.isWallMount()) {
        roomSize.yMin = this.config.walabotConfig.yMin;
      }
    } else {
      roomSize.xMin = convertFeetAndInchesToMeters(
        this.roomSize.get("roomXMinFeet").value,
        this.roomSize.get("roomXMinInches").value
      );
      roomSize.xMax = convertFeetAndInchesToMeters(
        this.roomSize.get("roomXMaxFeet").value,
        this.roomSize.get("roomXMaxInches").value
      );
      roomSize.yMin = convertFeetAndInchesToMeters(
        this.roomSize.get("roomYMinFeet").value,
        this.roomSize.get("roomYMinInches").value
      );
      roomSize.yMax = convertFeetAndInchesToMeters(
        this.roomSize.get("roomYMaxFeet").value,
        this.roomSize.get("roomYMaxInches").value
      );
      if (this.isWallMount()) {
        roomSize.yMin = this.config.walabotConfig.yMin;
      }
    }

    return roomSize;
  }

  getSubregionsFromForm(): Array<TrackerSubRegion> {
    const subRegions: Array<TrackerSubRegion> = [];
    for (const control of this.subRegions.controls) {
      let xMinValueM: number,
        xMaxValueM: number,
        yMinValueM: number,
        yMaxValueM: number,
        zMinValueM: number,
        zMaxValueM: number;
      if (this.isMetric()) {
        xMinValueM = control.get("xMin").value as number;
        xMaxValueM = control.get("xMax").value as number;
        yMinValueM = control.get("yMin").value as number;
        yMaxValueM = control.get("yMax").value as number;
        zMinValueM = control.get("zMin").value as number;
        zMaxValueM = control.get("zMax").value as number;
      } else {
        xMinValueM = convertFeetAndInchesToMeters(
          control.get("xMinFeet").value as number,
          control.get("xMinInches").value as number
        );
        xMaxValueM = convertFeetAndInchesToMeters(
          control.get("xMaxFeet").value as number,
          control.get("xMaxInches").value as number
        );
        yMinValueM = convertFeetAndInchesToMeters(
          control.get("yMinFeet").value as number,
          control.get("yMinInches").value as number
        );
        yMaxValueM = convertFeetAndInchesToMeters(
          control.get("yMaxFeet").value as number,
          control.get("yMaxInches").value as number
        );
        zMinValueM = convertFeetAndInchesToMeters(
          control.get("zMinFeet").value as number,
          control.get("zMinInches").value as number
        );
        zMaxValueM = convertFeetAndInchesToMeters(
          control.get("zMaxFeet").value as number,
          control.get("zMaxInches").value as number
        );
      }

      const trackerSubRegion: TrackerSubRegion = {
        xMin: xMinValueM,
        xMax: xMaxValueM,
        yMin: yMinValueM,
        yMax: yMaxValueM,
        zMin: zMinValueM,
        zMax: zMaxValueM,
        enterDuration: control.get("enterDuration").value as number,
        exitDuration: control.get("exitDuration").value as number,
        isFallingDetection: control.get("isFallingDetection").value as boolean,
        isPresenceDetection: control.get("isPresenceDetection")
          .value as boolean,
        isHorizontal: control.get("isHorizontal").value as boolean,
        isLowSnr: control.get("isLowSnr").value as boolean,
        isDoor: control.get("isDoor").value as boolean,
        name: control.get("name").value as string,
      };
      subRegions.push(trackerSubRegion);
    }

    return subRegions;
  }

  getSettings() {
    return <Record<string, any>>this.configurationForm.getRawValue();
  }

  getSensorHeightFromForm() {
    const sensorMountingValue = parseInt(
      this.deviceSettings.get("sensorMounting").value as string,
      10
    );
    if (this.isMetric()) {
      let sensorHeight = this.roomSize.get("roomCeilingHeight").value;
      if (
        sensorMountingValue === SensorMounting.Wall &&
        this.roomSizeComponent?.standardSensorHeightWasSelected
      ) {
        sensorHeight = ROOM_LIMITS_IN_METERS.standardHeight;
      }
      if (
        this.config.walabotConfig.sensorHeight.toFixed(
          SENSOR_HEIGHT_MAX_DIGITS_AFTER_DECIMAL_POINT
        ) === sensorHeight?.toString()
      ) {
        sensorHeight = this.config.walabotConfig.sensorHeight;
      }
      return sensorHeight;
    } else {
      return convertFeetAndInchesToMeters(
        this.roomSize.get("roomCeilingHeightFeet").value,
        this.roomSize.get("roomCeilingHeightInches").value
      );
    }
  }

  private showWarningMessageAndChangeTabIfNeeded(
    title: string,
    message: string,
    tabIndex: TABS
  ) {
    const okBtnText = $localize`:@@ok:OK`;
    const cancelBtnText = $localize`:@@cancel:Cancel`;
    const data: DialogData = {
      title,
      message,
      typeDialog: DialogType.MESSAGE,
      okBtnText,
      cancelBtnText,
      btnHandler: (dialog, confirmed) => {
        dialog.close(confirmed);
      },
    };

    return this.dialog
      .open(DialogComponent, {
        panelClass: "warning-dialog",
        data,
      })
      .afterClosed()
      .toPromise()
      .then((confirmed: boolean) => {
        if (confirmed) {
          // go to tab with invalid values
          this.selectedTabIndex = tabIndex;
        } else {
          // sensor mount - restore previous value
          this.deviceSettings
            .get("sensorMounting")
            .setValue(this.config.walabotConfig.sensorMounting);
        }

        return confirmed;
      })
      .catch((e) => {
        console.error(e);
      });
  }
}

<div class="header">
    <span class="suite-overview">{{suite?.suite.name}} <span i18n="@@overview">Overview</span></span>
</div>
<div class="rooms">
    <ng-container *ngFor="let room of suite?.rooms">
        <ng-container *appVar="(room.devices | keyvalue)[0] as device">
            <div *ngIf="device" class="room">
                <div class="room-type-select-trigger">
                    <svg class="status-icon" viewBox="0 0 60 50">
                        <use [attr.xlink:href]="getDeviceIcon(room.room.type)"></use>
                    </svg>
                    <span>{{
                        getDeviceName(room.room.type)
                        }}</span>
                </div>
                <div class="device-info">
                    <span><span i18n="@@room-number">Room Name</span>: <span class="opaque-text">{{ room.room.name }}</span></span>
                    <span><span i18n="@@serial-number">Serial Number</span>: <span class="opaque-text">{{ deviceInfo[device.key]?.androidSerial }}</span></span>
                    <span><span class="field-title" i18n="@@device-id">Device ID</span>: <span class="opaque-text">{{ device.key }}</span></span>
                </div>
                <div class="actions">
                    <button mat-stroked-button
                            [attr.data-id]="device.key"
                            (click)="onConfigureDevice(device.value)">Configure</button>
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="onMoveDevice(room.room)">
                            <span i18n="@@move">Move</span>
                        </button>
                        <button mat-menu-item (click)="onDeleteDevice(device.value, room.room)">
                            <span i18n="@@delete">Delete</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>

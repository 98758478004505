<div class="container page">
    <div class="header">
        <div>
            <h1>Vayyar Care Alerts</h1>
            <span class="facility-name">{{getUserNameAndEmail(user).displayName}} <span class="apartment-number">({{suitesNumber}} <span i18n="@@apartments">Apartments</span>)</span></span>
        </div>
        <button *ngIf="isAdmin" mat-raised-button i18n="@@simulate-fall-alert" (click)="onSimulateFall()" class="simulate-alert">Simulate Fall Alert</button>
    </div>
    <div class="main">
        <div class="alerts">
            <h3>Active Alerts</h3>
            <ng-container *ngIf="monitoringService.alerts.length > 0; else noAlertContainer">
                <span class="alerts-number">{{monitoringService.alerts.length}}</span>
                <table>
                    <ng-container *ngFor="let alert of monitoringService.alerts">
                        <ng-container [ngSwitch]="alert.status">
                            <tr *ngSwitchCase="AlertStatus.Active" class="row fall-detected" [attr.data-device-id]="alert.deviceId" (mockFall)="mockFallAlert(alert.deviceId)" (mockDismissFall)="mockDismissFall(alert.roomId)">
                                <td>
                                    <img src="../../assets/icons/alert.svg">
                                </td>
                                <td>
                                    <span class="status" i18n="@@fall-detected">Fall Detected</span>
                                </td>
                                <td>
                                    <div>
                                        <span class="suite">{{alert.suiteName}} - {{alert.roomName}}</span><br>
                                        <span class="datetime">{{alert.createdAt | date: dateTimeFormat}} {{ zoneAbbr }}</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="time-info">
                                        <span>{{(Date.now() - Date.parse(alert.createdAt)) / 1000 | secondsToDuration}}</span><br>
                                        <span class="datetime" i18n="@@since-alert">Since alert</span>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngSwitchCase="AlertStatus.Taken" class="row fall-taken" [attr.data-device-id]="alert.deviceId" (mockFall)="mockFallAlert(alert.deviceId)" (mockDismissFall)="mockDismissFall(alert.roomId)">
                                <td>
                                    <img src="../../assets/icons/alert-taken.svg">
                                </td>
                                <td>
                                    <span class="status" i18n="@@fall-taken">Fall Taken</span>
                                </td>
                                <td>
                                    <div>
                                        <span class="suite">{{alert.suiteName}} - {{alert.roomName}}</span><br>
                                        <span class="datetime">{{alert.createdAt | date: dateTimeFormat}} {{ zoneAbbr }}</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="time-info">
                                        <span>{{alert.takenAt | date: 'hh:mm a'}}</span><br>
                                        <span class="datetime" i18n="@@time-taken">Time taken</span>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                </table>
            </ng-container>
            <ng-template #noAlertContainer>
                <div i18n="@@no-active-alerts" class="no-active-alerts">No active alerts</div>
            </ng-template>
        </div>
        <div class="disconnections">
            <h3>Unprotected Apartments</h3> <span class="disconnections-number">{{monitoringService.disconnectedCount}}</span>
            <ng-container *ngIf="monitoringService.disconnectedCount > 0; else noDisconnectedContainer">
                <table>
                    <ng-container *ngFor="let deviceRoomSuite of disconnectedStatusSuiteArr; trackBy: suiteTrackBy">
                        <tr class="row" [attr.data-device-id]="deviceRoomSuite.room.device.deviceId">
                            <td>
                                <img src="../../assets/icons/disconnected.svg">
                            </td>
                            <td>
                                <span class="suite">{{deviceRoomSuite.name}} - {{deviceRoomSuite.room.name}}</span>
                            </td>
                            <td>
                            <span class="datetime">
                                <span i18n="@@since" class="since">Since</span>{{deviceStateMap[deviceRoomSuite.room.device.deviceId].updateTimestamp | date: dateTimeFormat}} {{ zoneAbbr }}
                            </span>
                            </td>
                        </tr>
                    </ng-container>
                </table>
            </ng-container>
            <ng-template #noDisconnectedContainer>
                <div i18n="@@no-unprotected-apartments" class="no-unprotected-apartments">No unprotected apartments</div>
            </ng-template>
        </div>
    </div>
</div>

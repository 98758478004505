<div style="display: flex; width: 100%; height: 100%">
  <mat-spinner *ngIf="!_device || !config" style="margin: auto"></mat-spinner>
  <form
    *ngIf="_device && config"
    style="width: 100%; height: 100%"
    [formGroup]="configurationForm"
  >
    <mat-tab-group class="custom-tabs" style="width: 100%; height: 100%" [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChanged($event)" [@.disabled]="true">
      <mat-tab disabled>
        <ng-template mat-tab-label style="min-width: unset;">
          <span class="cdk-visually-hidden" #translatedTooltipMessageBack i18n="@@back">Back</span>
          <button mat-icon-button [matTooltip]="translatedTooltipMessageBack.innerHTML" matTooltipPosition="right" (click)="back.emit()" data-cy="back-to-suite-button">
            <span class="material-icons export-devices-button">arrow_back</span>
          </button>
        </ng-template>
      </mat-tab>
      <mat-tab
        class="mat-tab-label-text"
      >
        <ng-template mat-tab-label>
          <span data-cy="room-settings-tab" class="tab-label" i18n="@@room-and-device-settings">ROOM AND DEVICE SETTINGS</span>
          <div *ngIf="deviceSettings.invalid" class="errors-count" matTooltip="{{getTooltipInvalidData()}}">
            {{ getCountOfInvalidFields(deviceSettings)}}
          </div>
        </ng-template>
        <app-general-details appElementDirective #generalDetailsComponent="ngDirective"
                             [configurationForm]="configurationForm"
                             [config]="config"
                             [type]="room?.type"
                             [suite]="suite"
                             [room]="room"
                             [_device]="_device"
                             [deviceInfo]="deviceInfo"
                             [lastSeen]="lastSeen"
                             [wifiState]="wifiState"
                             [inE2ETest]="inE2ETest"
                             [validateRoomName]="validateRoomName"
                             (validateRoomSize)="validateRoomSizeAgainstSensorMount()"
                             (assign)="assign.emit()"
        >
          <div *ngIf="isLearning()" class="learning-notification">
            <span i18n="@@learning-notification">Device is in learning mode, no alerts will be sent. Ends in</span>
            {{ getLearningModeStr() }}.
          </div>
        </app-general-details>
      </mat-tab>
      <mat-tab
        class="mat-tab-label-text"
      >
        <ng-template mat-tab-label>
          <span data-cy="room-size-tab" class="tab-label" i18n="@@room-size-label">ROOM SIZE</span>
          <div *ngIf="roomSize.invalid" class="errors-count" matTooltip="{{getTooltipInvalidData()}}">
            {{ getCountOfInvalidFields(roomSize)}}
          </div>
        </ng-template>
        <app-room-size
                appElementDirective #roomComponent="ngDirective"
          *ngIf="configurationForm"
          [configurationForm]="configurationForm"
          [config]="config"
          [unit]="unit"
          [isWallMount]="isWallMount()"
          [controlLastUpdateTimestamp]="controlLastUpdateTimestamp"
          (roomChanged)="validateSubregionSizeAgainstRoomChanged($event)"
        >
          <ng-template appZippyContent>
            <ng-container *ngTemplateOutlet="previewTemplate; context: {component: roomComponent, isSubRegionsEditable: false}"></ng-container>
          </ng-template>
        </app-room-size>
      </mat-tab>
      <mat-tab
        class="mat-tab-label-text"
      >
        <ng-template mat-tab-label>
          <span data-cy="subregions-tab" class="tab-label" i18n="@@sub-regions-label">SUBREGIONS</span>
          <div *ngIf="subRegions.invalid" class="errors-count" matTooltip="{{getTooltipInvalidData()}}">
            {{ getCountOfInvalidFields(subRegions)}}
          </div>
        </ng-template>
        <app-sub-regions appElementDirective #regionsComponentEl="ngDirective" #regionsComponent
          *ngIf="roomSize"
          [configurationForm]="configurationForm"
          [isWallMount]="isWallMount()"
          [unit]="unit"
          [suite]="suite"
          [config]="config"
          [is3DViewSelected]="is3DViewSelected"
          [controlLastUpdateTimestamp]="controlLastUpdateTimestamp"
          [getRoomSize]="getRoomSizeFromForm.bind(this)"
          [getSensorHeight]="getSensorHeightFromForm.bind(this)"
          (subregionChanged)="validateRoomSizeAgainstSubregionChanged($event)"
          (updateTimestamp)="updateLastUpdateTimestamp($event)"
          (drawSubRegion)="drawSubRegionEventEmitter.emit()"
        >
          <ng-template appZippyContent>
            <ng-container *ngTemplateOutlet="previewTemplate; context: {component: regionsComponentEl, isSubRegionsEditable: true}"></ng-container>
          </ng-template>
        </app-sub-regions>
      </mat-tab>
      <mat-tab disabled>
        <ng-template mat-tab-label>
          <div
            style="
              display: flex;
              width: 100%;
              flex-direction: row;
              align-items: center;
            "
          >
            <button
              mat-button
              class="reset-btn"
              data-cy="reset-button"
              [disabled]="
                !configurationForm.dirty ||
                inProgress
              "
              (click)="reset(getDeviceName(_device.type), _device.type)"
              i18n="@@reset"
            >
              Reset
            </button>
            <button
              mat-button
              class="save-btn"
              data-cy="save-button"
              [disabled]="
                !configurationForm.dirty ||
                inProgress ||
                configurationForm.invalid
              "
              (click)="saveConfiguration()"
            >
              <div class="spinner-wrapper">
                <mat-spinner diameter="15" *ngIf="inProgress"> </mat-spinner>
              </div>
              <span i18n="@@save">Save</span>
            </button>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </form>
</div>

<ng-template #previewTemplate let-component="component" let-isSubRegionsEditable="isSubRegionsEditable">
  <span *ngIf="!is3DViewSelected" i18n="@@top-view" class="top-view">Top View</span>
  <mat-button-toggle-group [(value)]="is3DViewSelected" class="switch-view" [attr.data-value]="is3DViewSelected">
    <mat-button-toggle [value]="false">2D</mat-button-toggle>
    <mat-button-toggle [value]="true">3D</mat-button-toggle>
  </mat-button-toggle-group>
  <div *ngIf="!inE2ETest && component.elementRef.nativeElement.offsetWidth"
       style="width: 100%; height: calc(100% - 10px); overflow: hidden; display: flex;">
    <app-preview2d [hidden]="is3DViewSelected"
                   [getSettings]="getSettings.bind(this)"
                   [config]="configurationForm.valueChanges | async"
                   [roomSize]="roomSize"
                   [subRegions]="subRegions"
                   [drawSubRegion]="drawSubRegionEventEmitter"
                   [selectedSubRegionIndex]="component.selectedSubRegionIndex"
                   [getRoomSizeFromForm]="getRoomSizeFromForm.bind(this)"
                   [getSubregionsFromForm]="getSubregionsFromForm.bind(this)"
                   [isMetric]="isMetric.bind(this)"
                   [isSubRegionsEditable]="isSubRegionsEditable"
                   (newSelectedSubRegionIndex)="component.selectedSubRegionIndex = $event"
                   (addSubRegion)="addSubRegion($event)">
    </app-preview2d>
    <app-preview3d [hidden]="!is3DViewSelected"
                   [getSettings]="getSettings.bind(this)"
                   [config]="configurationForm.valueChanges | async"
                   [roomSize]="roomSize"
                   [subRegions]="subRegions"
                   [selectedSubRegionIndex]="component.selectedSubRegionIndex"
                   [getRoomSizeFromForm]="getRoomSizeFromForm.bind(this)"
                   [getSubregionsFromForm]="getSubregionsFromForm.bind(this)"
                   [getSensorHeightFromForm]="getSensorHeightFromForm.bind(this)">
    </app-preview3d>
  </div>
</ng-template>

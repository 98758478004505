/**
 * TODO: Add documentation. What is this for?
 * Why is shouldFallNotificationAppear here?
 * It seems like it should be some place else.
 */

import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { environment } from "../environments/environment";
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from "@danielmoncada/angular-datetime-picker";
import { AppComponent, UiModule } from "@walabot-mqtt-dashboard/ui";
import { FallEvent, SensitiveFallEvent } from "@walabot-mqtt-dashboard/api";

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule.withConfig({
      disableAnimations: "Cypress" in window,
    }),
    CommonModule,
    HttpClientModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    UiModule,
  ],
  providers: [
    {
      provide: "environment",
      useValue: environment,
    },
    /**
     * Show fall notification for fall event when device
     * - was in "test mode"
     * - or
     * - was not in "silent mode" and was not in "learning mode"
     */
    {
      provide: "shouldFallNotificationAppear",
      useValue: function shouldFallNotificationAppear(
        event: FallEvent | SensitiveFallEvent,
      ) {
        return event.isSimulated || (!event.isSilent && !event.isLearning);
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

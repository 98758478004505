/**
 * TODO: Change the name of this and the other related components to device manager
 */

import {
  Component,
  ChangeDetectorRef,
  OnInit,
  HostListener,
  ViewChildren,
  QueryList,
  AfterViewInit,
  ElementRef,
  Inject,
  HostBinding,
  ViewChild,
} from "@angular/core";
import { MediaMatcher } from "@angular/cdk/layout";
import {
  Router,
  NavigationEnd,
  ActivationStart,
  ActivatedRoute,
} from "@angular/router";
import {
  transition,
  trigger,
  query,
  style,
  animate,
  group,
} from "@angular/animations";
import {
  DeviceMonitoringService,
  AuthService,
  AppUser,
  EventBusService,
  UserService as AdminUserService,
  ConfigService,
  RoomsService,
  SuitesService,
} from "../ui.module";
import { takeUntil } from "rxjs/operators";
import packageJson from "../../../../../package.json";
import { OpenAPI, PairedDevice } from "@walabot-mqtt-dashboard/api";
import axios from "axios";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { BehaviorSubject } from "rxjs";
import { BaseComponent } from "../base-component";
import { UserRegion, getLatestUserRegion } from "../auth.service";
import { Environment } from "../models";
import { LoginComponent } from "../login/login.component";
import { getUserNameAndEmail } from "../utils";
import firebase from "firebase/compat";

enum PostMessageType {
  IFRAME_APP_READY,
}

function uuidv4() {
  return crypto.randomUUID();
}

interface MessageEventData {
  type: PostMessageType;
  url?: string;
  displayName?: string;
  email?: string;
}

interface UserIFrameElement {
  uuid: string;
  isAdmin: boolean;
  displayName?: string;
  email?: string;
  iFrame?: HTMLIFrameElement;
  iFrameReady: boolean;
}

const transitionDuration = "0.25s";
const DEFAULT_URL_SLUG = "installer";

export const slideInAnimation = trigger("routeAnimations", [
  transition("* <=> *", [
    query(
      ":enter",
      [style({ opacity: 0, position: "absolute", top: 0, width: "100%" })],
      {
        optional: true,
      }
    ),
    group([
      query(
        ":leave",
        [
          style({ opacity: 1 }),
          animate(
            transitionDuration,
            style({ opacity: 0, position: "absolute", top: 0, width: "100%" })
          ),
        ],
        { optional: true }
      ),
      query(
        ":enter",
        [
          style({ opacity: 0 }),
          animate(
            transitionDuration,
            style({ opacity: 1, position: "relative" })
          ),
        ],
        { optional: true }
      ),
    ]),
  ]),
]);

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  animations: [slideInAnimation],
})
export class AppComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @ViewChildren("iframe") iframes: QueryList<ElementRef<HTMLIFrameElement>>;
  @ViewChild(LoginComponent) loginComponent: LoginComponent;

  title = "WalabotMqttDashboard";
  mobileQuery: MediaQueryList;
  mqttStateStr: string;
  appVersion: string;
  isLoggedIn: boolean;
  isAdminDashboardUser: boolean;
  hasDevices: boolean;
  user: AppUser["user"];
  userImageUrl: string;
  defaultUserImageUrl = "../assets/icons/account_circle.svg";
  userUpdating = false;
  showLogo: boolean;
  origin: SafeResourceUrl;
  insideIFrame = window.frameElement;
  userIFrames: Array<UserIFrameElement> = [];
  activeUserIFrameIndex = 0;
  userTabsPresented = false;
  releaseNotesPresented = false;
  DEFAULT_URL_SLUG = DEFAULT_URL_SLUG;
  basePath: string = DEFAULT_URL_SLUG;
  defaultRoute = "rooms";
  latestUserRegion: string;
  private _mobileQueryListener: () => void;
  private releaseNotesCloseKey = "latestClosedReleaseVersion";

  @HostListener("window:message", ["$event"]) onPostMessage = (
    $event: MessageEvent
  ) => {
    if ($event.isTrusted) {
      const data: MessageEventData = $event.data as MessageEventData;
      let iFrameElement: UserIFrameElement;
      switch (data?.type) {
        case PostMessageType.IFRAME_APP_READY:
          iFrameElement = this.userIFrames.find((item) => {
            return item.iFrame === ($event.source as Window).frameElement;
          });
          iFrameElement.displayName = data.displayName;
          iFrameElement.email = data.email;
          iFrameElement.iFrameReady = true;
          if (iFrameElement.email !== getUserNameAndEmail(this.user).email) {
            this.loginToUserFromIframe();
          }
          this.saveUserIFramesList();
          break;
      }
    }
  };
  @HostBinding("style.--transition-duration") get transitionDuration() {
    return transitionDuration;
  }

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    protected router: Router,
    public deviceService: DeviceMonitoringService,
    public eventBus: EventBusService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private adminUserService: AdminUserService,
    private sanitizer: DomSanitizer,
    private configService: ConfigService,
    private roomsService: RoomsService,
    private suiteService: SuitesService,
    @Inject("environment") public environment: { [key: string]: Environment }
  ) {
    super();
    this.isLoggedIn = null;
    this.hasDevices = null;
    if (packageJson && packageJson.version) {
      this.appVersion = packageJson.version;
    }
    this.mobileQuery = media.matchMedia("(max-width: 600px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.matches &&
      this.mobileQuery.addEventListener("change", this._mobileQueryListener);

    if (environment.multiRegion) {
      const latestUserRegion = getLatestUserRegion();
      this.latestUserRegion = latestUserRegion;
      OpenAPI.BASE = environment.apiUrl[latestUserRegion] as string;
    } else {
      OpenAPI.BASE = this.environment.apiUrl as string;
    }
    OpenAPI.TOKEN = this.getToken;
    this.showLogo = this.environment.showLogo as boolean;
    this.origin = this.sanitizer.bypassSecurityTrustResourceUrl(
      document.location.origin
    );
    this.releaseNotesPresented =
      localStorage.getItem(this.releaseNotesCloseKey) !== this.appVersion;
  }

  ngOnInit() {
    if (!this.authService.currentUser) {
      return;
    }
    this.authService.currentUser
      .pipe(takeUntil(this.ngUnsubsrcibe))
      .subscribe((appUser) => {
        console.log("app authService.currentUser: ", appUser);
        this.authServiceSubscribe(appUser).catch((err) => {
          console.log(err);
        });
      });
    this.adminUserServiceSubscribe();
    this.devicesSubscribe();
    this.routerEventsSubscribe();

    this.errorInterceptor();
  }

  async authServiceSubscribe(appUser: AppUser) {
    if (appUser) {
      if (appUser.user) {
        const basePath = localStorage.getItem("basePath") || DEFAULT_URL_SLUG,
          defaultURL = `/${basePath}/${this.defaultRoute}`;

        if (this.insideIFrame) {
          window.parent.postMessage({
            type: PostMessageType.IFRAME_APP_READY,
            displayName: getUserNameAndEmail(appUser.user).displayName,
            email: getUserNameAndEmail(appUser.user).email,
          });
        } else {
          if (
            sessionStorage.getItem("isAdminDashboardUser") ||
            (await this.adminUserService.isDashboardUser())
          ) {
            await this.userIFramesSettingForAdmin(appUser);
          }
          if (this.user) {
            this.deviceService.closeWebSocket();
          }

          this.user = appUser.user;
          this.userUpdating = false;
          this.userImageUrl =
            this.user && this.user.photoURL
              ? this.user.photoURL
              : this.defaultUserImageUrl;
          let devices: Array<PairedDevice> = [];
          if (!this.loginComponent?.register) {
            devices = await this.deviceService.getPairedDevices(true);
            void this.configService.loadConfig();
            void this.roomsService.init();
            void this.suiteService.init();
          } else {
            this.deviceService.ready.next(true);
          }
          this.hasDevices = devices.length > 0;
          if (this.hasDevices) {
            let apiURL: string;
            if (this.environment.multiRegion) {
              const latestUserRegion = getLatestUserRegion();
              apiURL = this.environment.apiUrl[latestUserRegion] as string;
            } else {
              apiURL = this.environment.apiUrl as string;
            }
            void this.deviceService.registerWebSocket(apiURL);
          }
          this.isLoggedIn = !appUser.user.isAnonymous;
          if (this.router.url === "/") {
            await this.router.navigateByUrl(defaultURL);
          }

          await this.checkMultiRegionUser(appUser);
        }
      } else {
        this.isLoggedIn = false;
        this.hasDevices = null;
        this.deviceService.clear();
      }
    } else {
      this.isLoggedIn = null;
      this.hasDevices = null;
      this.deviceService.clear();
      //this.router.navigateByUrl('/');
    }
  }

  async userIFramesSettingForAdmin(appUser: AppUser) {
    if (!this.userIFrames.length) {
      const savedUserIFrames = localStorage.getItem("userIFrames"),
        savedActiveUserIFrameIndex = localStorage.getItem(
          "activeUserIFrameIndex"
        );

      if (savedUserIFrames) {
        this.userIFrames = JSON.parse(
          savedUserIFrames
        ) as Array<UserIFrameElement>;
        if (savedActiveUserIFrameIndex) {
          this.activeUserIFrameIndex = Number(savedActiveUserIFrameIndex);
        }
      }
    }
    if (await this.adminUserService.isDashboardUser()) {
      if (!this.userIFrames.length) {
        this.userIFrames.push({
          isAdmin: true,
          uuid: uuidv4(),
          displayName: getUserNameAndEmail(appUser.user).displayName,
          email: getUserNameAndEmail(appUser.user).email,
          iFrameReady: false,
        });
        this.saveUserIFramesList();
      }
      if (indexedDB) {
        /**
         * Copy admin credentials from indexedDB to localStorage
         * so we can restore admin session from iFrame when
         * admin tab activated
         */
        const openRequest = indexedDB.open("firebaseLocalStorageDb");
        openRequest.onsuccess = () => {
          const fbKey = `firebase:authUser:${
              (this.environment.fbConfig as Record<string, unknown>)
                .apiKey as string
            }:[DEFAULT]`,
            db = openRequest.result,
            query = db
              .transaction("firebaseLocalStorage", "readonly")
              .objectStore("firebaseLocalStorage")
              .get(fbKey);
          query.onsuccess = () => {
            const uuid = this.userIFrames[this.activeUserIFrameIndex].uuid;
            localStorage.setItem(
              `${uuid}:${fbKey}`,
              JSON.stringify((query.result as Record<string, any>).value)
            );
          };
        };
      }
    }
  }

  adminUserServiceSubscribe() {
    this.adminUserService.currentUser
      .pipe(takeUntil(this.ngUnsubsrcibe))
      .subscribe(() => {
        this.adminUserService
          .isDashboardUser()
          .then((isAdminDashboardUser) => {
            this.isAdminDashboardUser = isAdminDashboardUser;
            if (isAdminDashboardUser) {
              sessionStorage.setItem("isAdminDashboardUser", "true");
            }
            if (sessionStorage.getItem("isAdminDashboardUser")) {
              this.userTabsPresented = true;
            } else {
              this.userTabsPresented = false;
            }
          })
          .catch((err) => console.warn(err));
      });
  }

  devicesSubscribe() {
    this.deviceService.devices
      .pipe(takeUntil(this.ngUnsubsrcibe))
      .subscribe((devices) => {
        console.log("devices changed", devices);
        //TODO: When no devices are configured in room but the user have paired devices we don't want to show the login page
        //this.hasDevices = devices && devices.length > 0;
      });
  }

  routerEventsSubscribe() {
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationStart) {
        const path = event.snapshot.url[0]?.path;
        if (["monitor", "installer", "admin"].includes(path)) {
          localStorage.setItem("basePath", path);
          this.basePath = path;
        }
      }
      if (event instanceof NavigationEnd) {
        const route = this.router.routerState.snapshot.root.firstChild;
        this.title = route
          ? (route.data.title as string)
            ? (route.data.title as string)
            : this.title
          : this.title;
        if (this.isAdminDashboardUser) {
          this.saveUserIFramesList();
        }
      }
    });
  }

  ngAfterViewInit() {
    this.iframes.changes.pipe(takeUntil(this.ngUnsubsrcibe)).subscribe(() => {
      this.iframes.forEach((item) => {
        const uuid = item.nativeElement.getAttribute("data-id"),
          userIFrameElement = this.userIFrames.find((el) => el.uuid === uuid);

        if (userIFrameElement) {
          userIFrameElement.iFrame = item.nativeElement;
        }
      });
    });
  }

  private getToken = async () => {
    return await this.authService.getToken();
  };

  // Detect users who are already in login status after the multi region login function.
  async checkMultiRegionUser(appUser: AppUser) {
    if (this.environment.multiRegion && !("Cypress" in window)) {
      const latestUserRegionStr = localStorage.getItem("latest-user-region");
      const email = appUser.user.email;
      const region = localStorage.getItem(`user-${email}-latest-region`);
      // console.log("In multi regional websites,", region, latestUserRegionStr, email);
      if (!region || !latestUserRegionStr) {
        console.warn(
          "In multi regional websites, some local data of users is missing."
        );
        await this.logout();
        window.location.reload();
        return;
      }
      const latestUserRegion = JSON.parse(latestUserRegionStr) as UserRegion;
      if (latestUserRegion?.region !== region) {
        console.warn(
          "In a multi regional website, some local data of users is incorrect."
        );
        await this.logout();
        window.location.reload();
      }
    }
  }

  async logout() {
    this.userIFrames = [];
    sessionStorage.removeItem("isAdminDashboardUser");
    this.deviceService.closeWebSocket();
    await this.authService.logout();
    this.deviceService.clear();
    this.eventBus.clear();
  }

  private errorInterceptor() {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        this.showErrorMsg($localize`:@@internal-error:Internal Error`);
        return Promise.reject(error);
      }
    );
  }

  showErrorMsg(msg: string) {
    this.snackBar.open(msg, "X", {
      duration: 3000,
      horizontalPosition: "left",
      panelClass: ["success-msg", "error"],
    });
  }

  inAdminMode() {
    return this.basePath === "admin";
  }

  addUserIFrame(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.userIFrames.push({
      isAdmin: false,
      uuid: uuidv4(),
      email: "New user",
      iFrameReady: false,
    });
    this.activeUserIFrameIndex = this.userIFrames.length - 1;
    this.saveUserIFramesList();
    this.saveActiveUserIFrameIndex();
  }

  removeUserIFrame(iFrameElement: UserIFrameElement) {
    const indexOfIFrame = this.userIFrames.indexOf(iFrameElement);
    if (indexOfIFrame === this.activeUserIFrameIndex) {
      this.activeUserIFrameIndex = indexOfIFrame - 1;
      this.saveActiveUserIFrameIndex();
    }
    const [userToRemove] = this.userIFrames.splice(indexOfIFrame, 1);
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(userToRemove.uuid)) {
        localStorage.removeItem(key);
      }
    });
    this.saveUserIFramesList();
  }

  onUserTabChanged() {
    this.userUpdating = true;
    this.saveActiveUserIFrameIndex();
    if (this.userIFrames[this.activeUserIFrameIndex].iFrameReady) {
      this.loginToUserFromIframe();
    }
  }

  closeReleaseNotes() {
    localStorage.setItem(this.releaseNotesCloseKey, this.appVersion);
    this.releaseNotesPresented = false;
  }

  getUserNameAndEmail(user: firebase.User) {
    return getUserNameAndEmail(user);
  }

  private saveUserIFramesList() {
    const userIFrames = JSON.parse(
      JSON.stringify(this.userIFrames)
    ) as Array<UserIFrameElement>;
    userIFrames.forEach((item) => (item.iFrameReady = false));
    localStorage.setItem("userIFrames", JSON.stringify(userIFrames));
  }

  private saveActiveUserIFrameIndex() {
    localStorage.setItem(
      "activeUserIFrameIndex",
      String(this.activeUserIFrameIndex)
    );
  }

  private loginToUserFromIframe() {
    const subscription = (
      this.userIFrames[this.activeUserIFrameIndex].iFrame.contentWindow[
        "currentUser"
      ] as BehaviorSubject<AppUser>
    ).subscribe((user) => {
      if (user?.user) {
        void this.authService.updateCurrentUser(user);
        setTimeout(() => {
          subscription.unsubscribe();
        });
      }
    });
  }
}

import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { BaseComponent } from "../../base-component";
import { KeyValue } from "@angular/common";
import {
  ConfigService,
  DeviceMonitoringService,
  DeviceRoomConfigMapping,
  getDeviceIcon,
  RoomsService,
  RoomTypeMapping,
} from "../../ui.module";
import {
  DeviceRoomConfig,
  PairedDevice,
  Room,
  RoomType,
  Suite,
} from "@walabot-mqtt-dashboard/api";
import { map, startWith, take, takeUntil } from "rxjs/operators";
import { combineLatest, Observable } from "rxjs";
import { DeviceSettingsComponent } from "../device-settings/device-settings.component";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { UntypedFormControl } from "@angular/forms";
import { MatExpansionPanel } from "@angular/material/expansion";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { SuitesService } from "../../suites.service";
import {
  DialogComponent,
  DialogData,
  DialogType,
} from "../../dialog/dialog.component";
import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { saveAs } from "file-saver";

interface DeviceWithSerialRoomConfig extends DeviceRoomConfig {
  androidSerial?: string;
  filteredBy: "id" | "serial";
}

interface PairedDeviceWithSerial extends PairedDevice {
  selected?: boolean;
  filteredBy: "id" | "serial";
}

interface SuiteInfo {
  suite: Suite;
  rooms: Array<{ room: Room; devices: DeviceRoomConfigMapping }>;
}

@Component({
  selector: "app-rooms-settings",
  templateUrl: "./rooms-settings.component.html",
  styleUrls: ["./rooms-settings.component.css"],
})
export class RoomsSettingsComponent extends BaseComponent implements OnInit {
  @ViewChildren(DeviceSettingsComponent)
  private deviceSettings: QueryList<DeviceSettingsComponent>;

  @ViewChild("panelUnassigned")
  panelUnassigned: MatExpansionPanel;

  searchBar: UntypedFormControl = new UntypedFormControl();
  filteredSearchOptions: Observable<DeviceWithSerialRoomConfig[]>;
  suiteInfoBySuiteId: Map<string, SuiteInfo>;
  unassignedDevices: Map<string, PairedDeviceWithSerial>;
  selectedDevice: DeviceRoomConfig;
  selectedSuiteId: string;
  allPairedDevices: Map<string, PairedDevice> = new Map();
  allRoomLinkedDevices: DeviceWithSerialRoomConfig[] = [];
  filteredSearchUnassignedDevices: Observable<PairedDeviceWithSerial[]>;
  filteredSearchRoom: Observable<Room[]>;
  suiteList: Array<Suite> = [];

  roomInfoOrder = (
    a: KeyValue<string, SuiteInfo>,
    b: KeyValue<string, SuiteInfo>
  ): number => {
    return this.nameOrder(a.value.suite.name, b.value.suite.name);
  };
  nameOrder = (a: string, b: string): number => {
    return Number.isNaN(+a) || Number.isNaN(+b)
      ? a.localeCompare(b)
      : Number(a) - Number(b);
  };

  suiteId: string;
  roomId: string;

  constructor(
    private deviceConfigService: ConfigService,
    private deviceService: DeviceMonitoringService,
    private roomsService: RoomsService,
    private suitesService: SuitesService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.suiteInfoBySuiteId = new Map();
    this.unassignedDevices = new Map();
    if (this.route.paramMap) {
      this.route.paramMap.subscribe((params) => {
        if (params && params.get("suiteId")) {
          this.suiteId = params.get("suiteId");
          this.roomId = params.get("roomId");
        }
      });
    }
    combineLatest([
      this.suitesService.suiteList,
      this.suitesService.roomMapping,
      this.roomsService.roomList,
      this.roomsService.deviceMapping,
      this.deviceService.devices,
    ])
      .pipe(takeUntil(this.ngUnsubsrcibe))
      .subscribe(
        ([suiteList, roomMapping, roomList, deviceMapping, pairedDevices]) => {
          this.suiteList = suiteList.sort((a, b) =>
            this.nameOrder(a.name, b.name)
          );
          const unassignedMap: Map<string, PairedDeviceWithSerial> = new Map();
          const suiteMap: Map<string, SuiteInfo> = new Map();
          if (!roomList || !deviceMapping || !pairedDevices) {
            return;
          }
          this.allPairedDevices.clear();
          pairedDevices.forEach((device: PairedDeviceWithSerial) => {
            unassignedMap.set(device.id, device);
            this.allPairedDevices.set(device.id, device);
          });
          suiteList.forEach((suite) => {
            let currentSuite = suiteMap.get(suite.id);
            if (!currentSuite) {
              currentSuite = { suite: suite, rooms: [] };
            } else {
              currentSuite.suite = suite;
            }
            currentSuite.rooms = (roomMapping[suite.id] || []).map((room) => ({
              room,
              devices: {},
            }));
            suiteMap.set(suite.id, currentSuite);
          });
          const allRoomLinkedDevices: Array<DeviceWithSerialRoomConfig> = [];
          Object.values(deviceMapping).forEach(
            (device: DeviceWithSerialRoomConfig) => {
              const suiteId = Object.values(roomMapping)
                .flat()
                .find((room) => {
                  return room.id === device.roomId;
                })?.suiteId;
              if (suiteId) {
                unassignedMap.delete(device.deviceId);
              }
              if (this.allPairedDevices.get(device.deviceId)) {
                device.androidSerial = this.allPairedDevices.get(
                  device.deviceId
                ).androidSerial;
                if (suiteMap.get(suiteId)) {
                  suiteMap
                    .get(suiteId)
                    .rooms.find(
                      (room) => room.room.id === device.roomId
                    ).devices[device.deviceId] = device;
                }
              }
              allRoomLinkedDevices.push(device);
            }
          );
          this.allRoomLinkedDevices = allRoomLinkedDevices;
          this.unassignedDevices = unassignedMap;
          this.suiteInfoBySuiteId = suiteMap;
          if (this.selectedDevice) {
            const newSelectedDevice = this.allRoomLinkedDevices.find(
              (device) => device.deviceId === this.selectedDevice.deviceId
            );
            if (newSelectedDevice) {
              void this.deviceSelected(newSelectedDevice);
            }
          } else if (this.roomId) {
            setTimeout(() => {
              const newSelectedDevice = this.allRoomLinkedDevices.find(
                (device) => device.roomId === this.roomId
              );
              if (newSelectedDevice) {
                void this.deviceSelected(newSelectedDevice);
              }
            });
          }
        }
      );
    this.filteredSearchOptions = this.searchBar.valueChanges.pipe(
      startWith(""),
      map((value: string) => this._filter(value))
    );

    this.filteredSearchUnassignedDevices = this.searchBar.valueChanges.pipe(
      startWith(""),
      map((value: string) => this._filterUnassigned(value))
    );

    this.filteredSearchRoom = this.searchBar.valueChanges.pipe(
      startWith(""),
      map((value: string) => this._filterSuite(value))
    );
  }

  private _filterSuite(value: string) {
    return Array.from(this.suiteInfoBySuiteId.values())
      .filter((el) => {
        return el.suite.name.toLowerCase().includes(value.toLowerCase());
      })
      .map((el) => el.suite);
  }

  private _filter(value: string): DeviceWithSerialRoomConfig[] {
    return this.allRoomLinkedDevices.filter((device) => {
      return this._filterType(value, "deviceId", device);
    });
  }

  private _filterUnassigned(value: string): PairedDeviceWithSerial[] {
    return Array.from(this.unassignedDevices.values()).filter((device) => {
      return this._filterType(value, "id", device);
    });
  }

  private _filterType(
    value: string,
    keyName: string,
    device: DeviceWithSerialRoomConfig | PairedDeviceWithSerial
  ) {
    let filteredByID = false,
      filteredBySerial = false;
    if (
      (device[keyName] as string).toLowerCase().indexOf(value.toLowerCase()) ===
      0
    ) {
      filteredByID = true;
      device.filteredBy = "id";
    } else if (
      device.androidSerial?.toLowerCase().indexOf(value.toLowerCase()) === 0
    ) {
      filteredBySerial = true;
      device.filteredBy = "serial";
    }
    return filteredByID || filteredBySerial;
  }

  isSelected(device: DeviceRoomConfig) {
    return (
      this.selectedDevice &&
      this.selectedDevice.deviceId === device.deviceId &&
      this.selectedDevice.roomId === device.roomId
    );
  }

  optionSelected(event: MatAutocompleteSelectedEvent) {
    if (this._filterUnassigned(event.option.value as string).length) {
      this.unassignedDeviceSelected(
        this._filterUnassigned(event.option.value as string)[0]
      );
    } else if (this._filter(event.option.value as string).length) {
      void this.deviceSelected(this._filter(event.option.value as string)[0]);
    } else if (this._filterSuite(event.option.value as string).length) {
      this.suiteSelected(this._filterSuite(event.option.value as string)[0].id);
    }
  }

  getDeviceIcon(type: RoomType) {
    return getDeviceIcon(type);
  }

  getDeviceName(type: RoomType) {
    return RoomTypeMapping.get(type).name;
  }

  getTotalNumberOfUnassignedDevicesText() {
    return $localize`:@@total-number-of-unassigned-devices:Unassigned Devices (${this.unassignedDevices.size}:totalNumber:)`;
  }

  async deviceSelected(device: DeviceRoomConfig) {
    const previouslySelectedDevice = this.selectedDevice;
    this.selectedDevice = device;
    const suite = this.suiteList.find((suite) => {
      return this.suiteInfoBySuiteId.get(suite.id).rooms.find((room) => {
        return room.room.id === device.roomId;
      });
    });
    this.selectedSuiteId = null;
    suite && (this.selectedSuiteId = suite?.id);
    if (!previouslySelectedDevice) {
      await new Promise((resolve) => {
        this.deviceSettings.changes.pipe(take(1)).subscribe(resolve);
      });
    }
    this.deviceSettings.first.device = { ...device };
    let deviceRoom: Room;
    Array.from(this.suiteInfoBySuiteId.values()).find((el) =>
      el.rooms.find((room) => {
        const found = room.room.id === device.roomId;
        if (found) {
          deviceRoom = room.room;
        }
      })
    );
    this.deviceSettings.first.suite = null;
    this.deviceSettings.first.room = null;
    suite && (this.deviceSettings.first.suite = suite);
    deviceRoom && (this.deviceSettings.first.room = deviceRoom);
  }

  unassignedDevicesToArray(): PairedDeviceWithSerial[] {
    return Array.from(this.unassignedDevices.values());
  }

  assignedDevicesDropped(event: CdkDragDrop<string[]>) {
    const device = this.unassignedDevicesToArray()[event.previousIndex];
    const suite = this.suiteInfoBySuiteId.get(
      event.container.element.nativeElement.dataset.suiteId
    );
    this.assignToSuite(device, suite.suite);
  }

  unassignedDeviceSelected(device: PairedDeviceWithSerial) {
    device.selected = true;
    this.panelUnassigned.open();
    setTimeout(() => {
      delete device.selected;
    }, 600);
  }

  suiteSelected(suiteId: string) {
    this.selectedDevice = null;
    this.selectedSuiteId = suiteId;
  }

  addSuite() {
    const data: DialogData = {
      title: $localize`:@@add-suite:Add Suite`,
      fieldTitle: $localize`:@@enter-suite-name:Enter Suite Name`,
      typeDialog: DialogType.INPUT,
      okBtnText: $localize`:@@add-suite:Add Suite`,
      cancelBtnText: $localize`:@@cancel:Cancel`,
      btnHandler: (dialog, confirmed, suiteName: string) => {
        if (!confirmed) {
          dialog.close();
        } else {
          this.suitesService
            .addSuite(suiteName)
            .then(() => {
              dialog.close();
            })
            .catch((error) => {
              dialog.close();
              console.error(`Unable to add suite ${suiteName}: `, error);
            });
        }
      },
    };

    this.dialog.open(DialogComponent, {
      panelClass: "pairing-dialog",
      data,
    });
  }

  addDeviceToSuite(event: PointerEvent, suite: Suite) {
    event.stopPropagation();
    const unassignedDevices = [...this.unassignedDevices.values()];
    const data: DialogData<PairedDevice> = {
      title: $localize`:@@assign-device-to-suite:Assign a Device to ${suite.name}:suiteName:`,
      fieldTitle: $localize`:@@select-device:Select device`,
      list: unassignedDevices,
      displayNameForList: "androidSerial",
      typeDialog: DialogType.SELECT,
      okBtnText: $localize`:@@assign-device:Assign Device`,
      cancelBtnText: $localize`:@@cancel:Cancel`,
      btnHandler: (dialog, confirmed, device: PairedDevice) => {
        if (!confirmed) {
          dialog.close();
        } else {
          this.assignDeviceToSuite(device, suite)
            .then(() => {
              dialog.close();
            })
            .catch((error) => {
              console.error(error);
              dialog.close();
            });
        }
      },
    };

    this.dialog.open(DialogComponent<PairedDevice>, {
      panelClass: "pairing-dialog",
      data,
      autoFocus: false,
    });
  }

  editSuite(suite: Suite) {
    const data: DialogData = {
      title: $localize`:@@edit-suite:Edit Suite`,
      fieldTitle: $localize`:@@enter-suite-name:Enter Suite Name`,
      inputField: suite.name,
      typeDialog: DialogType.INPUT,
      okBtnText: $localize`:@@save:Save`,
      cancelBtnText: $localize`:@@cancel:Cancel`,
      btnHandler: (dialog, confirmed, suiteName: string) => {
        if (!confirmed) {
          dialog.close();
        } else {
          this.suitesService
            .updateSuite(suite.id, suiteName)
            .then(() => {
              dialog.close();
            })
            .catch((error) => {
              dialog.close();
              console.error(`Unable to update suite ${suite.id}: `, error);
            });
        }
      },
    };

    this.dialog.open(DialogComponent, {
      panelClass: "pairing-dialog",
      data,
    });
  }

  deleteSuite(suite: Suite) {
    const deviceCount: number = this.getAllDevicesInSuite(suite).length;
    const message1stLine: string =
      deviceCount > 0
        ? deviceCount > 1
          ? $localize`:@@device-count-in-suite-plural:You have ${deviceCount}:deviceCount: devices inside the suite<br/>.`
          : $localize`:@@device-count-in-suite-single:You have 1 device inside the suite<br/>.`
        : "";

    const message: string = $localize`:@@delete-suite-message:${message1stLine}:message1stLine:<br/> Are you sure you want to delete ${suite.name}:suiteName: ?`;

    const data: DialogData = {
      title: $localize`:@@delete-suite-title:Delete ${suite.name}:suiteName:`,
      message,
      typeDialog: DialogType.MESSAGE,
      okBtnText: $localize`:@@delete-suite:Delete Suite`,
      cancelBtnText: $localize`:@@cancel:Cancel`,
      btnHandler: (dialog, confirmed) => {
        if (!confirmed) {
          dialog.close();
        } else {
          this.suitesService
            .removeSuite(suite.id)
            .then(() => {
              this.selectedDevice = null;
              if (this.deviceSettings.first) {
                this.deviceSettings.first.config = null;
              }
              const removeRoomsPool = this.suiteInfoBySuiteId
                .get(suite.id)
                .rooms.map((roomElement) => {
                  return this.roomsService.removeRoom(roomElement.room.id);
                });
              Promise.all(removeRoomsPool)
                .then(() => {
                  Promise.all([
                    this.suitesService.reLoadSuiteList(),
                    this.roomsService.loadDeviceRoomMapping(),
                  ])
                    .then(() => {
                      dialog.close();
                    })
                    .catch((error) => {
                      dialog.close();
                      console.error(`Unable to reload suite list: `, error);
                    });
                })
                .catch((error) => {
                  dialog.close();
                  console.error(`Unable to remove room: `, error);
                });
            })
            .catch((error) => {
              dialog.close();
              console.error(`Unable to remove suite ${suite.id}: `, error);
            });
        }
      },
    };
    this.dialog.open(DialogComponent, {
      panelClass: "warning-dialog",
      data,
    });
  }

  unpair(deviceId: string) {
    const data: DialogData = {
      title: $localize`:@@unpair-device-title:Unpair ${deviceId}:deviceId:`,
      message: $localize`:@@unpair-device-message:Are you sure you want to unpair device ${deviceId}:deviceId:?`,
      typeDialog: DialogType.MESSAGE,
      okBtnText: $localize`:@@unpair-device:Unpair Device`,
      cancelBtnText: $localize`:@@cancel:Cancel`,
      btnHandler: (dialog, confirmed) => {
        if (!confirmed) {
          dialog.close();
        } else {
          this.deviceService
            .unpair(deviceId)
            .then(() => {
              this.showSuccessMsg(
                $localize`:@@device-unpaired-successfully:Device unpaired successfully`
              );
              dialog.close();
            })
            .catch(() => {
              this.showErrorMsg(
                $localize`:@@failed-to-unpair-device:Failed to unpair device`
              );
            });
        }
      },
    };
    this.dialog.open(DialogComponent, {
      panelClass: "warning-dialog",
      data,
    });
  }

  assignToSuite(device: PairedDeviceWithSerial, selectedSuite?: Suite) {
    const data: DialogData<Suite> = {
      title: $localize`:@@assign-device-to-a-suite:Assign Device To a Suite`,
      fieldTitle: $localize`:@@select-suite:Select Suite`,
      list: this.suiteList,
      selectedValue: selectedSuite,
      displayNameForList: "name",
      typeDialog: DialogType.SELECT,
      okBtnText: $localize`:@@assign-device:Assign Device`,
      cancelBtnText: $localize`:@@cancel:Cancel`,
      btnHandler: (dialog, confirmed, suite: Suite) => {
        if (!confirmed) {
          dialog.close();
        } else {
          this.assignDeviceToSuite(device, suite)
            .then(() => {
              dialog.close();
            })
            .catch((error) => {
              console.error(error);
              dialog.close();
            });
        }
      },
    };
    this.dialog.open(DialogComponent<Suite>, {
      panelClass: "pairing-dialog",
      data,
    });
  }

  getDevicesOfSuite(suite: SuiteInfo): string[] {
    let devicesList: string[] = [];
    suite.rooms.forEach((item) => {
      const devices = Object.keys(item.devices);
      if (devices.length > 0) devicesList = devicesList.concat(devices);
    });
    return devicesList;
  }

  moveDevice(currentRoom: Room, currentSuiteId: string) {
    const filteredSuites = this.suiteList.filter(
      (suite) => suite.id !== currentSuiteId
    );
    console.log("filtered " + JSON.stringify(filteredSuites));

    const data: DialogData<Suite> = {
      title: $localize`:@@move-device-to-a-different-suite:Move Device To a different Suite`,
      fieldTitle: $localize`:@@select-suite:Select Suite`,
      list: filteredSuites,
      displayNameForList: "name",
      typeDialog: DialogType.SELECT,
      okBtnText: $localize`:@@move-device:Move Device`,
      cancelBtnText: $localize`:@@cancel:Cancel`,
      btnHandler: (dialog, confirmed, suite: Suite) => {
        if (!confirmed) {
          dialog.close();
        } else {
          const roomsCountWithTheSameName = this.suiteInfoBySuiteId
            .get(suite.id)
            .rooms.filter(
              (room) =>
                room.room.name.toLowerCase() === currentRoom.name.toLowerCase()
            ).length;

          if (roomsCountWithTheSameName) {
            const message = $localize`:@@room-name-is-already-in-use-message:The suite where you want to move the device already has a room named ${currentRoom.name}:roomName:. Please rename the room first.`;
            const title = $localize`:@@room-name-is-already-in-use-title:Room name is already in use`;
            dialog.componentInstance.inProgress = false;
            return this.dialog.open(DialogComponent, {
              panelClass: "warning-dialog",
              data: {
                title,
                message,
                okBtnText: $localize`:@@ok:OK`,
                cancelBtnText: $localize`:@@cancel:Cancel`,
                typeDialog: DialogType.MESSAGE,
              },
            });
          }

          this.suitesService
            .assignRoomToSuite(currentRoom.id, suite.id)
            .then(() => {
              this.showSuccessMsg(
                $localize`:@@device-moved-to-suite:Device moved successfully to ${suite.name}:suiteName:`
              );

              dialog.close();
            })
            .catch(() => {
              this.showErrorMsg(
                $localize`:@@failed-to-move-device-to-suite:Failed to move device to ${suite.name}:suiteName:`
              );
            });
        }
      },
    };
    this.dialog.open(DialogComponent<Suite>, {
      panelClass: "pairing-dialog",
      data,
    });
  }

  deleteDevice(device: DeviceRoomConfig, room: Room, suite: Suite) {
    const deviceName = this.getDeviceName(device.type),
      suiteName = suite.name;
    const title = $localize`:@@delete-device-title:Delete ${deviceName}:deviceName: Device From Suite ${suiteName}:suiteName:`;
    const message = $localize`:@@delete-device-message:You are going to delete ${deviceName}:deviceName: Device
    From Suite ${suiteName}:suiteName:.<br/>All the device settings will be deleted aswell.<br/>
    Are you sure you want to delete ${deviceName}:deviceName: device?`;

    const data: DialogData = {
      title,
      message,
      typeDialog: DialogType.MESSAGE,
      okBtnText: $localize`:@@delete-device:Delete Device`,
      cancelBtnText: $localize`:@@cancel:Cancel`,
      btnHandler: (dialog, confirmed) => {
        if (!confirmed) {
          dialog.close();
        } else {
          this.roomsService
            .removeDeviceFromRoom(device.deviceId, room.id)
            .then(async () => {
              this.selectedDevice = null;
              if (this.deviceSettings.first) {
                this.deviceSettings.first.config = null;
              }
              const roomConfig = this.suiteInfoBySuiteId
                .get(suite.id)
                .rooms.find((roomConfig) => roomConfig.room.id === room.id);
              if (Object.keys(roomConfig.devices).length === 0) {
                await this.roomsService.removeRoom(room.id);
                this.suiteInfoBySuiteId
                  .get(suite.id)
                  .rooms.splice(
                    this.suiteInfoBySuiteId
                      .get(suite.id)
                      .rooms.indexOf(roomConfig),
                    1
                  );
              }
              this.showSuccessMsg(
                $localize`:@@device-deleted-from-suite:Device deleted successfully from ${suiteName}:suiteName:`
              );
              dialog.close();
            })
            .catch(() => {
              this.showErrorMsg(
                $localize`:@@failed-to-delete-from-suite:Failed to delete device from ${suiteName}:suiteName:`
              );
            });
        }
      },
    };
    this.dialog.open(DialogComponent, {
      panelClass: "warning-dialog",
      data,
    });
  }

  showSuccessMsg(msg: string) {
    this.snackBar.open(msg, "X", {
      duration: 3000,
      horizontalPosition: "left",
      panelClass: "success-msg",
    });
  }

  showErrorMsg(msg: string) {
    this.snackBar.open(msg, "X", {
      duration: 3000,
      horizontalPosition: "left",
      panelClass: ["success-msg", "error"],
    });
  }

  async openAdvanced(id: string) {
    await this.router.navigateByUrl(`advanced/${id}`);
  }

  convertRoomTypeNumberToName(roomTypeID: number) {
    return RoomType[roomTypeID].toString();
  }

  getRoomNameValidatorInCurrentSuite(roomName: string) {
    const roomsCountWithTheSameName =
      this.suiteInfoBySuiteId
        .get(this.selectedSuiteId)
        ?.rooms.filter((room) => {
          return (
            room.room.id !== this.deviceSettings.first.room.id &&
            room.room.name.toLowerCase() === roomName.toLowerCase()
          );
        }).length ?? 0;

    return roomsCountWithTheSameName !== 0;
  }

  async exportDevices() {
    const blobString = await this.deviceConfigService.exportDeviceRelateInfo(); // The actual returned type is string
    const date = new Date();
    const month = date.getMonth() + 1;
    saveAs(
      new Blob([blobString]),
      `device-relate-info-${date.getFullYear()}-${month}-${date.getDate()}.csv`,
      { autoBom: true }
    );
  }

  private generateRoomName(roomType: RoomType, ...suiteIds: Array<string>) {
    let roomName = RoomTypeMapping.get(parseInt(String(roomType), 10)).name;
    let roomsCountWithTheSameName = this.suiteInfoBySuiteId
      .get(suiteIds[0])
      .rooms.filter((room) =>
        room.room.name.toLowerCase().startsWith(roomName.toLowerCase())
      ).length;
    while (
      suiteIds
        .map((suiteId) => this.suiteInfoBySuiteId.get(suiteId).rooms)
        .flat()
        .find(
          (room) =>
            room.room.name === `${roomName} ${roomsCountWithTheSameName + 1}`
        )
    ) {
      roomsCountWithTheSameName++;
    }
    roomName += ` ${roomsCountWithTheSameName + 1}`;

    return roomName;
  }

  private getAllDevicesInSuite(suite: Suite) {
    return this.suiteInfoBySuiteId
      .get(suite.id)
      .rooms.map((room) => Object.values(room.devices))
      .flat();
  }

  private assignDeviceToSuite(device: PairedDevice, suite: Suite) {
    const deviceAssignedRoom = this.allRoomLinkedDevices.find(
      (el) => el.deviceId === device.id
    );
    if (deviceAssignedRoom) {
      return this.assignRoomToSuite(
        suite,
        deviceAssignedRoom.roomId,
        device.id
      );
    } else {
      const roomName = this.generateRoomName(device.roomType, suite.id);
      return this.roomsService
        .addRoom(roomName, device.roomType)
        .then((room) => {
          return this.assignRoomToSuite(suite, room.id, device.id);
        });
    }
  }

  private assignRoomToSuite(suite: Suite, roomId: string, deviceId: string) {
    return this.suitesService.assignRoomToSuite(roomId, suite.id).then(() => {
      return this.roomsService
        .assignDeviceToRoom(deviceId, roomId)
        .then(() => {
          this.showSuccessMsg(
            $localize`:@@device-added-to-suite:Device added successfully to ${suite.name}:suiteName:`
          );
          this.selectedSuiteId = suite.id;
        })
        .catch(() => {
          this.showErrorMsg(
            $localize`:@@failed-to-add-device-to-suite:Failed to add device to ${suite.name}:suiteName:`
          );
        });
    });
  }
}

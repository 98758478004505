import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  ChartConfiguration,
  ChartData,
  ChartType,
  DoughnutController,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
} from "chart.js";
import { RiskTrendPiece } from "../ui.module";
import { DatePipe } from "@angular/common";
import {
  AveragePresenceData,
  FallsByShift,
  RiskLevel,
} from "../analytics.service";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { firstUnique } from "../utils";

const FallsByShiftConfig: {
  [shift_name: string]: {
    background: string;
    color: string;
  };
} = {
  "Day Morning": {
    background: `#2CAAE2`,
    color: "black",
  },
  "Day PM": {
    background: `#ABA334`,
    color: "black",
  },
  Night: {
    background: `#007BA9`,
    color: "white",
  },
};

const DAYS_OF_WEEK = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

/* eslint-disable */
const totalizer = {
  id: "totalizer",
  beforeUpdate: (chart) => {
    const totals = {};
    let utmost = 0;
    chart.data.datasets.forEach((dataset, datasetIndex) => {
      if (chart.isDatasetVisible(datasetIndex)) {
        utmost = datasetIndex;
        dataset.data.forEach((value) => {
          totals[value.day_of_week] = (totals[value.day_of_week] || 0) + value.num_of_falls;
        });
      }
    });
    chart.$totalizer = {
      totals: totals,
      utmost: utmost,
    };
  },
};
/* eslint-enable */

@Component({
  selector: "app-chart",
  templateUrl: "./chart.component.html",
  styleUrls: ["./chart.component.css"],
})
export class ChartComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() type: ChartType;
  @Input() data:
    | Array<RiskTrendPiece>
    | AveragePresenceData
    | Array<FallsByShift> = [];
  @Input() yMin: number;
  @Input() yMax: number;
  @Input() risk_level: Array<RiskLevel>;
  @ViewChild("canvas") private canvas: ElementRef<HTMLCanvasElement>;
  insideUnitTest = false;
  private chart: Chart;
  constructor(private datePipe: DatePipe) {
    Chart.register(
      LineController,
      BarController,
      LineElement,
      BarElement,
      PointElement,
      LinearScale,
      CategoryScale,
      DoughnutController,
      ArcElement,
      Legend
    );
  }

  ngAfterViewInit() {
    if (!this.insideUnitTest) {
      const data = this.generateChartData();
      let chartConfig: ChartConfiguration;
      switch (this.type) {
        case "line":
          chartConfig = {
            type: this.type,
            data,
            options: {
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                x: {
                  grid: {
                    display: false,
                  },
                  offset: true,
                },
                y: {
                  grid: {
                    borderDash: [5, 5],
                  },
                  offset: true,
                  min: this.yMin,
                  max: this.yMax,
                  beginAtZero: true,
                },
              },
              elements: {
                line: {
                  borderWidth: 1,
                },
                point: {
                  radius: 4.5,
                  borderWidth: 0,
                  hoverRadius: 4.5,
                  hoverBorderWidth: 0,
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
            },
          };
          break;
        case "doughnut":
          chartConfig = {
            plugins: [ChartDataLabels],
            type: this.type,
            data,
            options: {
              // eslint-disable-next-line
              // @ts-ignore
              radius: "70%",
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: "left",
                  labels: {
                    usePointStyle: true,
                  },
                },
              },
            },
          };
          break;
        case "bar":
          chartConfig = {
            plugins: [ChartDataLabels, totalizer],
            type: this.type,
            data,
            options: {
              layout: {
                padding: {
                  left: 10,
                  right: 10,
                  top: 30,
                  bottom: 0,
                },
              },
              responsive: true,
              maintainAspectRatio: false,
              parsing: {
                xAxisKey: "day_of_week",
                yAxisKey: "num_of_falls",
              },
              plugins: {
                legend: {
                  position: "right",
                  labels: {
                    usePointStyle: true,
                  },
                  reverse: true,
                },
                datalabels: {
                  labels: {
                    value: {
                      anchor: "center",
                      align: "center",
                      color: "white",
                      font: {
                        size: 10,
                      },
                      formatter: (value: FallsByShift) => value.num_of_falls,
                      display: (ctx) =>
                        // eslint-disable-next-line
                        // @ts-ignore
                        ctx.dataset.data[ctx.dataIndex].num_of_falls > 0,
                    },
                    sum: {
                      anchor: "end",
                      align: "end",
                      color: "black",
                      formatter: (value, ctx) => {
                        /* eslint-disable */
                        // @ts-ignore
                        return ctx.chart.$totalizer.totals[value.day_of_week];
                        /* eslint-enable */
                      },
                      display: function (ctx) {
                        /* eslint-disable */
                        // @ts-ignore
                        return ctx.datasetIndex === ctx.chart.$totalizer.utmost;
                        /* eslint-enable */
                      },
                    },
                  },
                },
              },
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                  title: {
                    display: true,
                    text: "Number of Falls",
                  },
                  ticks: {
                    precision: 0,
                  },
                },
              },
            },
          };
          break;
      }
      this.chart = new Chart(this.canvas.nativeElement, chartConfig);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.insideUnitTest && "data" in changes && this.chart) {
      this.chart.data = this.generateChartData();
      this.chart.update();
    }
  }

  ngOnDestroy() {
    this.chart?.destroy();
  }

  private generateChartData() {
    let data: typeof this.data,
      ret: ChartData,
      values: AveragePresenceData["data"],
      total: number,
      percentages: Array<number>,
      totalPercentage: number,
      difference: number,
      valueToUpdateIndex: number;

    const dataByShift: {
      [day_of_week: number]: Array<FallsByShift>;
    } = {};
    switch (this.type) {
      case "line":
        data = this.data as Array<RiskTrendPiece>;
        ret = {
          labels: data.map((row) =>
            this.datePipe.transform(row.month, "MMM YYYY")
          ),
          datasets: [
            {
              label: "My First Dataset",
              data: data.map((row) => row.risk_score),
              fill: false,
              borderColor: "#49454F",
              backgroundColor: data.map(
                (row) =>
                  "#" +
                  this.risk_level.find(
                    (level) => row.risk_level_id === level.id
                  ).color
              ),
              tension: 0.1,
            },
          ],
        };
        break;
      case "doughnut":
        data = this.data as AveragePresenceData;
        values = data.data.map((value) =>
          parseFloat((value / 1000 / 60 / 60).toFixed(1))
        );
        total = values.reduce((sum, value) => sum + value, 0);
        percentages = values.map((value) => (value / total) * 100);
        // Round each percentage to 1 decimal place
        percentages = percentages.map((p) => parseFloat(p.toFixed(1)));
        // Calculate the total percentage after rounding
        totalPercentage = percentages.reduce(
          (sum, percentage) => sum + percentage,
          0
        );
        // Calculate the rounding difference
        difference = parseFloat((100 - totalPercentage).toFixed(1));
        // Adjust the first unique value by adding the difference to ensure the sum is 100
        valueToUpdateIndex = percentages.indexOf(
          firstUnique(percentages) ?? percentages[0]
        );
        percentages[valueToUpdateIndex] = parseFloat(
          (percentages[valueToUpdateIndex] + difference).toFixed(1)
        );
        ret = {
          labels: data.labels,
          datasets: [
            {
              label: "My First Dataset",
              data: data.data,
              backgroundColor: data.colors,
              hoverOffset: 4,
              datalabels: {
                formatter: function (value: number, context) {
                  return (
                    values[context.dataIndex].toString() +
                    ` (${percentages[context.dataIndex]}%)`
                  );
                },
                offset: function (context) {
                  return context.dataIndex % 2 ? 0 : 4;
                },
                display: "auto",
                anchor: "end",
                align: "end",
                color: "#67666C",
                font: {
                  size: 10,
                  family: "Roboto",
                },
              },
            },
          ],
        };
        break;
      case "bar":
        data = structuredClone(this.data as Array<FallsByShift>);
        data
          .filter((v) => v.day_of_week)
          .sort((a, b) => a.day_of_week - b.day_of_week)
          .forEach((v) => {
            if (!dataByShift[v.shift_id]) {
              dataByShift[v.shift_id] = [];
            }
            // eslint-disable-next-line
            // @ts-ignore
            v.day_of_week = DAYS_OF_WEEK[v.day_of_week - 1];
            const existedDataForDay = dataByShift[v.shift_id].find(
              (f) => f.day_of_week === v.day_of_week
            );
            if (existedDataForDay) {
              existedDataForDay.num_of_falls++;
            } else {
              dataByShift[v.shift_id].push(v);
            }
          });
        ret = {
          labels: DAYS_OF_WEEK,
          // eslint-disable-next-line
          // @ts-ignore
          datasets: Object.values(dataByShift)
            .sort((a, b) => a[0].shift_id - b[0].shift_id)
            .map((dataset) => ({
              data: dataset,
              label: dataset[0].shift_name,
              backgroundColor:
                FallsByShiftConfig[dataset[0].shift_name].background,
              barThickness: 22,
              datalabels: {
                labels: {
                  value: {
                    color: FallsByShiftConfig[dataset[0].shift_name].color,
                  },
                },
              },
            })),
        };
        break;
    }

    return ret;
  }
}

import { Component, OnInit } from "@angular/core";
import { StatusPageBase } from "../status-page-base";
import { getUserNameAndEmail, Mixin } from "../utils";
import { AppUser, UserService } from "../ui.module";
import { interval } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AlertStatus } from "@walabot-mqtt-dashboard/api";
import { moment } from "../models";

import * as momentTimezone from "moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js";
import firebase from "firebase/compat";
const moment: moment = momentTimezone as moment;

@Component({
  selector: "app-alerts",
  templateUrl: "./alerts.component.html",
  styleUrls: ["./alerts.component.css"],
})
export class AlertsComponent extends Mixin(StatusPageBase) implements OnInit {
  user: AppUser["user"];
  suitesNumber: number;
  AlertStatus = AlertStatus;
  Date = Date;
  zoneAbbr: string;

  ngOnInit() {
    super.ngOnInit();
    const userService = this.injector.get(UserService);
    userService.currentUser
      .pipe(takeUntil(this.ngUnsubsrcibe))
      .subscribe((user) => {
        this.user = user;
      });
    this.suitesService.suiteList
      .pipe(takeUntil(this.ngUnsubsrcibe))
      .subscribe((suiteList) => {
        this.suitesNumber = suiteList?.length ?? 0;
      });
    this.monitoringService.loadAlerts();
    interval(60 * 1000)
      .pipe(takeUntil(this.ngUnsubsrcibe))
      .subscribe(() => {
        this.monitoringService.loadAlerts();
      });

    const zone = moment.tz.guess();
    this.zoneAbbr = moment.tz(zone).format("z");
  }

  getUserNameAndEmail(user: firebase.User) {
    return getUserNameAndEmail(user);
  }
}

import { SuiteDetails } from "./models";
import { DashboardConfig } from "@walabot-mqtt-dashboard/api";
import { Validators } from "@angular/forms";
import firebase from "firebase/compat";

type Constructor = new (...args: any[]) => object;

export const Mixin = <T extends Constructor>(superclass: T = <T>class {}): T =>
  class extends superclass {};

export function getAllDevicesInSuite(suite: SuiteDetails, roomId?: string) {
  return Object.values(suite.rooms)
    .filter((room) => (roomId ? room.id === roomId : true))
    .map((room) => Object.values(room.devices))
    .flat();
}

export function isPresenceAlertTimesActive(generalConfig: DashboardConfig) {
  let retVal = false;
  if (
    generalConfig &&
    generalConfig.presenceAlertsEnabledFrom &&
    generalConfig.presenceAlertsEnabledFrom.length &&
    generalConfig.presenceAlertsEnabledTo &&
    generalConfig.presenceAlertsEnabledTo.length
  ) {
    const [presenceAlertsEnabledFromHours, presenceAlertsEnabledFromMinutes] =
      generalConfig.presenceAlertsEnabledFrom.split(":").map((v) => +v);
    const [presenceAlertsEnabledToHours, presenceAlertsEnabledToMinutes] =
      generalConfig.presenceAlertsEnabledTo.split(":").map((v) => +v);
    const presenceAlertsEnabledFrom = new Date();
    const presenceAlertsEnabledTo = new Date();
    const now = new Date();

    presenceAlertsEnabledFrom.setHours(presenceAlertsEnabledFromHours);
    presenceAlertsEnabledFrom.setMinutes(presenceAlertsEnabledFromMinutes);
    presenceAlertsEnabledFrom.setSeconds(0);
    presenceAlertsEnabledTo.setHours(presenceAlertsEnabledToHours);
    presenceAlertsEnabledTo.setMinutes(presenceAlertsEnabledToMinutes);
    presenceAlertsEnabledTo.setSeconds(0);
    if (presenceAlertsEnabledFrom < presenceAlertsEnabledTo) {
      retVal =
        now >= presenceAlertsEnabledFrom && now < presenceAlertsEnabledTo;
    } else if (presenceAlertsEnabledFrom > presenceAlertsEnabledTo) {
      retVal =
        now >= presenceAlertsEnabledFrom || now < presenceAlertsEnabledTo;
    } else {
      retVal = true;
    }
  }
  return retVal;
}

export const getPasswordValidator = () =>
  Validators.pattern(
    /^(?=.{8,15}$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*]).*$/gm
  );

export const getUserNameAndEmail = (user: firebase.User) => ({
  displayName: user?.displayName ?? user?.providerData[0]?.displayName,
  email: user?.email ?? user?.providerData[0]?.email,
});

export function firstUnique<T>(arr: Array<T>): T {
  const uniqueItems = arr.filter(
    (item) => arr.indexOf(item) === arr.lastIndexOf(item)
  );
  return uniqueItems.length > 0 ? uniqueItems[0] : null;
}

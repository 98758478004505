<div>
    <h3>Fall Risk per Apartment</h3>
    <div class="chips">
        <div *ngFor="let level of analytics?.dict.risk_level" class="chip" [ngStyle]="{backgroundColor: '#' + level.color}">
            {{level.name}}
        </div>
    </div>
</div>
<div class="tabs">
    <div class="tab" [class.active]="residencyStatus === 'on_site'" i18n="@@on-site" (click)="setResidencyStatus('on_site')">On Site</div>
    <div class="tab" [class.active]="residencyStatus === 'on_leave'" i18n="@@on-leave" (click)="setResidencyStatus('on_leave')">On Leave</div>
</div>
<span *ngIf="residencyStatus === 'on_site'" class="residency-description" i18n="@@on-site-description">Fall Risk for Residents on Site</span>
<span *ngIf="residencyStatus === 'on_leave'" class="residency-description" i18n="@@on-leave-description">Fall Risk for Residents on Leave of Absence</span>
<button mat-stroked-button type="button" class="btn btn-secondary download" (click)="downloadCSV()" tabindex="-1">
    <span class="material-icons material-symbols-outlined">output</span>
    <span i18n="@@export">Export</span>
</button>
<div  style="width: 100%; height: 100%; overflow: auto;">
    <table mat-table [dataSource]="topRiskResidentsByResidencyStatus">
        <ng-container matColumnDef="suite">
            <th mat-header-cell *matHeaderCellDef i18n="@@apartment">Apartment</th>
            <td mat-cell *matCellDef="let element" [ngStyle]="{color: getRiskLevelColor(element.risk_level_id)}"> {{element.suite_name}} </td>
        </ng-container>
        <ng-container matColumnDef="score">
            <th mat-header-cell *matHeaderCellDef i18n="@@risk-level">Risk Level</th>
            <td mat-cell *matCellDef="let element" [ngStyle]="{color: getRiskLevelColor(element.risk_level_id)}">
                {{element.risk_score}}
            </td>
        </ng-container>
        <ng-container matColumnDef="link">
            <th mat-header-cell *matHeaderCellDef>Apartment View Link</th>
            <td mat-cell *matCellDef="let element">
                <a routerLink="/{{basePath}}/insights/{{element.suite_id}}" (click)="close()">></a>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
<button mat-stroked-button i18n="@@close" class="close" (click)="close()">Close</button>

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SubregionAggregateStat } from '../models/SubregionAggregateStat';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SubregionStatAPI {

    /**
     * Get Subregion Stat
     * @param suiteId
     * @returns SubregionAggregateStat OK
     * @throws ApiError
     */
    public static querySuiteAggregateStat(
        suiteId?: string,
    ): CancelablePromise<Array<SubregionAggregateStat>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stats/aggregateStat',
            query: {
                'suiteId': suiteId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}

import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  OnChanges,
  SimpleChanges,
  ContentChild,
} from "@angular/core";
import {
  FormGroup,
  UntypedFormBuilder,
  Validators,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import { BaseComponent } from "../../../base-component";
import { MeasurementUnits } from "../../../ui.module";
import {
  filterNegativeValueFeet,
  filterNegativeValueInches,
  convertMetersToFeet,
  isFeetValueInRange,
  RegionErrorStateMatcher,
  convertFeetAndInchesToMeters,
} from "../device-settings-utils";
import { DeviceConfigGen2, SensorMounting } from "@walabot-mqtt-dashboard/api";
import {
  ROOM_LIMITS,
  ROOM_LIMITS_IN_METERS,
  SENSOR_HEIGHT_MAX_DIGITS_AFTER_DECIMAL_POINT,
} from "../room-size-limit";

import { RoomSizeForm, ControlRoom } from "../device-settings-interface";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { ZippyContentDirective } from "../../../ng-zip.directive";

const { required } = Validators as ValidationErrors;
const DEFAULT_ROOM_FORM_VALUE = 0;
const MIN_VALUE_INCHES = 0;
const MAX_VALUE_INCHES = 12;
const STANDARD_SENSOR_HEIGHT_FEET = convertMetersToFeet(
  ROOM_LIMITS_IN_METERS.standardHeight
);

@Component({
  selector: "app-room-size",
  templateUrl: "./room-size.component.html",
  styleUrls: ["./room-size.component.css"],
})
export class RoomSizeComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @ContentChild(ZippyContentDirective) content!: ZippyContentDirective;
  @Input() configurationForm: FormGroup;
  @Input() config: DeviceConfigGen2;
  @Input() unit: MeasurementUnits;
  @Input() isWallMount: boolean;
  @Input() controlLastUpdateTimestamp: ControlRoom;
  @Output() roomChanged = new EventEmitter();
  metric = MeasurementUnits.Metric;

  ceilingXMinMatcher = new RegionErrorStateMatcher([
    "ceilingXMinInchesInvalid",
    "xMinInvalid",
    "xMinAxisLengthInvalid",
  ]);
  ceilingXMaxMatcher = new RegionErrorStateMatcher([
    "ceilingXMaxInchesInvalid",
    "xMaxInvalid",
    "xMaxAxisLengthInvalid",
  ]);
  ceilingYMinMatcher = new RegionErrorStateMatcher([
    "ceilingYMinInchesInvalid",
    "yMinInvalid",
    "yMinAxisLengthInvalid",
  ]);
  ceilingYMaxMatcher = new RegionErrorStateMatcher([
    "ceilingYMaxInchesInvalid",
    "yMaxInvalid",
    "yMaxAxisLengthInvalid",
  ]);
  roomHeightMatcher = new RegionErrorStateMatcher([
    "roomHeightInvalid",
    "ceilingHeightInchesInvalid",
  ]);

  sensorHeightInMeters = +ROOM_LIMITS_IN_METERS.standardHeight.toFixed(
    SENSOR_HEIGHT_MAX_DIGITS_AFTER_DECIMAL_POINT
  );
  standardSensorHeightWasSelected = false;
  sensorHeightInFeet = STANDARD_SENSOR_HEIGHT_FEET;
  standardInstallationMetersOptionLabel = $localize`:@@standard-installation-meters:Standard (${ROOM_LIMITS_IN_METERS.standardHeight.toFixed(
    SENSOR_HEIGHT_MAX_DIGITS_AFTER_DECIMAL_POINT
  )}:height: m)`;
  standardInstallationFeetOptionLabel = $localize`:@@standard-installation-feet:Standard (${STANDARD_SENSOR_HEIGHT_FEET[0]}:feet:'${STANDARD_SENSOR_HEIGHT_FEET[1]}:inches:")`;
  lastSensorHeightFeetInputTriggerAutocomplete: Element;

  get roomSize() {
    return this.configurationForm.get("roomSizeForm");
  }

  get deviceSettings() {
    return this.configurationForm.get("deviceSettingsForm") as FormGroup;
  }

  /**
   * Checks that values edited by the user do not exceed the maximum and minimum room boundaries.
   * Room boundaries are set in the ROOM_LIMITS constant.
   */
  get roomSizeValidator(): ValidatorFn {
    return (group: FormGroup<RoomSizeForm>) => {
      const error = {};
      if (!(this.configurationForm && this.deviceSettings)) {
        return error;
      }
      const roomXMinUpdateTimestamp =
          this.controlLastUpdateTimestamp["roomXMin"],
        roomXMaxUpdateTimestamp = this.controlLastUpdateTimestamp["roomXMax"],
        yMinUpdateTimestamp = this.controlLastUpdateTimestamp["roomYMin"],
        yMaxUpdateTimestamp = this.controlLastUpdateTimestamp["roomYMax"];

      let ceilingXMin: number,
        ceilingXMax: number,
        ceilingYMin: number,
        ceilingYMax: number;
      let sensorMounting: "wall" | "ceiling" | "ceiling45" | "tilt45";
      switch (this.deviceSettings.get("sensorMounting").value) {
        case SensorMounting.Wall:
          sensorMounting = "wall";
          break;
        case SensorMounting.Ceiling:
          sensorMounting = "ceiling";
          break;
        case SensorMounting.Ceiling45Deg:
          sensorMounting = "ceiling45";
          break;
        case SensorMounting.Tilt45Deg:
          sensorMounting = "tilt45";
          break;
      }
      if (!sensorMounting) {
        return void 0;
      }
      if (this.isMetric()) {
        ceilingXMin = group.get("roomXMin").value;
        ceilingXMax = group.get("roomXMax").value;
        ceilingYMin = group.get("roomYMin").value;
        ceilingYMax = group.get("roomYMax").value;
        if (
          ceilingYMax < ROOM_LIMITS[sensorMounting].YMaxValueMin.meters ||
          ceilingYMax > ROOM_LIMITS[sensorMounting].YMaxValueMax.meters
        ) {
          error["yMaxInvalid"] = this.getErrorMessageValueShouldBeBetween(
            ROOM_LIMITS[sensorMounting].YMaxValueMin.meters,
            ROOM_LIMITS[sensorMounting].YMaxValueMax.meters
          );
        }
        if (
          ceilingXMin < ROOM_LIMITS[sensorMounting].XMinValueMin.meters ||
          ceilingXMin > ROOM_LIMITS[sensorMounting].XMinValueMax.meters
        ) {
          error["xMinInvalid"] = this.getErrorMessageValueShouldBeBetween(
            ROOM_LIMITS[sensorMounting].XMinValueMin.meters,
            ROOM_LIMITS[sensorMounting].XMinValueMax.meters
          );
        }
        if (
          ceilingXMax < ROOM_LIMITS[sensorMounting].XMaxValueMin.meters ||
          ceilingXMax > ROOM_LIMITS[sensorMounting].XMaxValueMax.meters
        ) {
          error["xMaxInvalid"] = this.getErrorMessageValueShouldBeBetween(
            ROOM_LIMITS[sensorMounting].XMaxValueMin.meters,
            ROOM_LIMITS[sensorMounting].XMaxValueMax.meters
          );
        }
        if (["wall", "tilt45"].includes(sensorMounting)) {
          const xAxisLength = Math.abs(ceilingXMin) + Math.abs(ceilingXMax);
          if (
            roomXMinUpdateTimestamp > roomXMaxUpdateTimestamp &&
            xAxisLength >
              ROOM_LIMITS[<"wall" | "tilt45">sensorMounting].xAxisLength.meter
          ) {
            error["xMinAxisLengthInvalid"] =
              this.getErrorMessageLengthXAxisCannotExceed(
                ROOM_LIMITS[<"wall" | "tilt45">sensorMounting].xAxisLength.meter
              );
          }
          if (
            roomXMinUpdateTimestamp < roomXMaxUpdateTimestamp &&
            xAxisLength >
              ROOM_LIMITS[<"wall" | "tilt45">sensorMounting].xAxisLength.meter
          ) {
            error["xMaxAxisLengthInvalid"] =
              this.getErrorMessageLengthXAxisCannotExceed(
                ROOM_LIMITS[<"wall" | "tilt45">sensorMounting].xAxisLength.meter
              );
          }
        } else {
          if (
            ceilingYMin <
              ROOM_LIMITS[sensorMounting as "ceiling"].YMinValueMin.meters ||
            ceilingYMin >
              ROOM_LIMITS[sensorMounting as "ceiling"].YMinValueMax.meters
          ) {
            error["yMinInvalid"] = this.getErrorMessageValueShouldBeBetween(
              ROOM_LIMITS[sensorMounting as "ceiling"].YMinValueMin.meters,
              ROOM_LIMITS[sensorMounting as "ceiling"].YMinValueMax.meters
            );
          }

          if (
            this.deviceSettings &&
            this.deviceSettings.get("sensorMounting").value ===
              SensorMounting.Ceiling45Deg
          ) {
            const yAxisLength = ceilingYMax - ceilingYMin;
            if (
              yMinUpdateTimestamp > yMaxUpdateTimestamp &&
              yAxisLength > ROOM_LIMITS.ceiling45.yAxisLength.meter
            ) {
              error["yMinAxisLengthInvalid"] =
                this.getErrorMessageLengthYAxisCannotExceed(
                  ROOM_LIMITS.ceiling45.yAxisLength.meter
                );
            }
            if (
              yMinUpdateTimestamp < yMaxUpdateTimestamp &&
              yAxisLength > ROOM_LIMITS.ceiling45.yAxisLength.meter
            ) {
              error["yMaxAxisLengthInvalid"] =
                this.getErrorMessageLengthYAxisCannotExceed(
                  ROOM_LIMITS.ceiling45.yAxisLength.meter
                );
            }
          }
        }
      }
      // feet validation
      else {
        const ceilingYMinFeet = group.get("roomYMinFeet").value;
        const ceilingYMinInches = group.get("roomYMinInches").value;

        const ceilingYMaxFeet = group.get("roomYMaxFeet").value;
        const ceilingYMaxInches = group.get("roomYMaxInches").value;

        const ceilingXMinFeet = group.get("roomXMinFeet").value;
        const ceilingXMinInches = group.get("roomXMinInches").value;

        const ceilingXMaxFeet = group.get("roomXMaxFeet").value;
        const ceilingXMaxInches = group.get("roomXMaxInches").value;
        // MIN_VALUE_INCHES <= inches < MAX_VALUE_INCHES validation ||
        // -MAX_VALUE_INCHES < inches < MAX_VALUE_INCHES validation
        let inchesInvalidError: string;
        if (
          ceilingYMaxInches <= -MAX_VALUE_INCHES ||
          ceilingYMaxInches >= MAX_VALUE_INCHES
        ) {
          if (ceilingYMaxFeet === 0) {
            inchesInvalidError = $localize`:@@negative-inches-invalid:${-MAX_VALUE_INCHES}:min: < inches < ${MAX_VALUE_INCHES}:max:`;
          } else {
            inchesInvalidError = $localize`:@@inches-invalid:${MIN_VALUE_INCHES}:min: <= inches < ${MAX_VALUE_INCHES}:max:`;
          }
          error["ceilingYMaxInchesInvalid"] = inchesInvalidError;
        }
        if (
          ceilingXMinInches <= -MAX_VALUE_INCHES ||
          ceilingXMinInches >= MAX_VALUE_INCHES
        ) {
          if (ceilingXMinFeet === 0) {
            inchesInvalidError = $localize`:@@negative-inches-invalid:${-MAX_VALUE_INCHES}:min: < inches < ${MAX_VALUE_INCHES}:max:`;
          } else {
            inchesInvalidError = $localize`:@@inches-invalid:${MIN_VALUE_INCHES}:min: <= inches < ${MAX_VALUE_INCHES}:max:`;
          }
          error["ceilingXMinInchesInvalid"] = inchesInvalidError;
        }
        if (
          ceilingXMaxInches <= -MAX_VALUE_INCHES ||
          ceilingXMaxInches >= MAX_VALUE_INCHES
        ) {
          if (ceilingXMaxFeet === 0) {
            inchesInvalidError = $localize`:@@negative-inches-invalid:${-MAX_VALUE_INCHES}:min: < inches < ${MAX_VALUE_INCHES}:max:`;
          } else {
            inchesInvalidError = $localize`:@@inches-invalid:${MIN_VALUE_INCHES}:min: <= inches < ${MAX_VALUE_INCHES}:max:`;
          }
          error["ceilingXMaxInchesInvalid"] = inchesInvalidError;
        }
        if (
          !isFeetValueInRange(
            ceilingYMaxFeet,
            ROOM_LIMITS[sensorMounting].YMaxValueMin.feet,
            ROOM_LIMITS[sensorMounting].YMaxValueMax.feet,
            ceilingYMaxInches,
            ROOM_LIMITS[sensorMounting].YMaxValueMin.inches,
            ROOM_LIMITS[sensorMounting].YMaxValueMax.inches
          )
        ) {
          error["yMaxInvalid"] = this.getErrorMessageValueShouldBeBetween(
            `${ROOM_LIMITS[sensorMounting].YMaxValueMin.feet}'${ROOM_LIMITS[sensorMounting].YMaxValueMin.inches}"`,
            `${ROOM_LIMITS[sensorMounting].YMaxValueMax.feet}'${ROOM_LIMITS[sensorMounting].YMaxValueMax.inches}"`
          );
        }

        if (
          !isFeetValueInRange(
            ceilingXMinFeet,
            ROOM_LIMITS[sensorMounting].XMinValueMin.feet,
            ROOM_LIMITS[sensorMounting].XMinValueMax.feet,
            ceilingXMinInches,
            ROOM_LIMITS[sensorMounting].XMinValueMin.inches,
            ROOM_LIMITS[sensorMounting].XMinValueMax.inches
          )
        ) {
          error["xMinInvalid"] = this.getErrorMessageValueShouldBeBetween(
            `${ROOM_LIMITS[sensorMounting].XMinValueMin.feet}'${ROOM_LIMITS[sensorMounting].XMinValueMin.inches}"`,
            `${ROOM_LIMITS[sensorMounting].XMinValueMax.feet}'${ROOM_LIMITS[sensorMounting].XMinValueMax.inches}"`
          );
        }
        if (
          !isFeetValueInRange(
            ceilingXMaxFeet,
            ROOM_LIMITS[sensorMounting].XMaxValueMin.feet,
            ROOM_LIMITS[sensorMounting].XMaxValueMax.feet,
            ceilingXMaxInches,
            ROOM_LIMITS[sensorMounting].XMaxValueMin.inches,
            ROOM_LIMITS[sensorMounting].XMaxValueMax.inches
          )
        ) {
          error["xMaxInvalid"] = this.getErrorMessageValueShouldBeBetween(
            `${ROOM_LIMITS[sensorMounting].XMaxValueMin.feet}'${ROOM_LIMITS[sensorMounting].XMaxValueMin.inches}"`,
            `${ROOM_LIMITS[sensorMounting].XMaxValueMax.feet}'${ROOM_LIMITS[sensorMounting].XMaxValueMax.inches}"`
          );
        }
        if (!["wall", "tilt45"].includes(sensorMounting)) {
          if (
            ceilingYMinInches <= -MAX_VALUE_INCHES ||
            ceilingYMinInches >= MAX_VALUE_INCHES
          ) {
            if (ceilingYMinFeet === 0) {
              inchesInvalidError = $localize`:@@negative-inches-invalid:${-MAX_VALUE_INCHES}:min: < inches < ${MAX_VALUE_INCHES}:max:`;
            } else {
              inchesInvalidError = $localize`:@@inches-invalid:${MIN_VALUE_INCHES}:min: <= inches < ${MAX_VALUE_INCHES}:max:`;
            }
            error["ceilingYMinInchesInvalid"] = inchesInvalidError;
          }

          if (
            !isFeetValueInRange(
              ceilingYMinFeet,
              ROOM_LIMITS[sensorMounting as "ceiling"].YMinValueMin.feet,
              ROOM_LIMITS[sensorMounting as "ceiling"].YMinValueMax.feet,
              ceilingYMinInches,
              ROOM_LIMITS[sensorMounting as "ceiling"].YMinValueMin.inches,
              ROOM_LIMITS[sensorMounting as "ceiling"].YMinValueMax.inches
            )
          ) {
            error["yMinInvalid"] = this.getErrorMessageValueShouldBeBetween(
              `${ROOM_LIMITS[sensorMounting as "ceiling"].YMinValueMin.feet}'${
                ROOM_LIMITS[sensorMounting as "ceiling"].YMinValueMin.inches
              }"`,
              `${ROOM_LIMITS[sensorMounting as "ceiling"].YMinValueMax.feet}'${
                ROOM_LIMITS[sensorMounting as "ceiling"].YMinValueMax.inches
              }"`
            );
          }

          if (
            this.deviceSettings &&
            this.deviceSettings.get("sensorMounting").value ===
              SensorMounting.Ceiling45Deg
          ) {
            const yAxisLength =
              convertFeetAndInchesToMeters(ceilingYMaxFeet, ceilingYMaxInches) -
              convertFeetAndInchesToMeters(ceilingYMinFeet, ceilingYMinInches);
            if (
              yMinUpdateTimestamp > yMaxUpdateTimestamp &&
              yAxisLength > ROOM_LIMITS.ceiling45.yAxisLength.meter
            ) {
              error["yMinAxisLengthInvalid"] =
                this.getErrorMessageLengthYAxisCannotExceed(
                  `${ROOM_LIMITS.ceiling45.yAxisLength.feet}'${ROOM_LIMITS.ceiling45.yAxisLength.inches}"`
                );
            }
            if (
              yMinUpdateTimestamp < yMaxUpdateTimestamp &&
              yAxisLength > ROOM_LIMITS.ceiling45.yAxisLength.meter
            ) {
              error["yMaxAxisLengthInvalid"] =
                this.getErrorMessageLengthYAxisCannotExceed(
                  `${ROOM_LIMITS.ceiling45.yAxisLength.feet}'${ROOM_LIMITS.ceiling45.yAxisLength.inches}"`
                );
            }
          }
        }
      }

      let height;
      const HEIGHT_LIMITS = ROOM_LIMITS[sensorMounting];

      if (this.isMetric()) {
        height = group.get("roomCeilingHeight").value;

        const isInvalidHeight =
          height < HEIGHT_LIMITS.heightMin.meters ||
          height > HEIGHT_LIMITS.heightMax.meters;
        if (
          sensorMounting === "wall" &&
          !this.standardSensorHeightWasSelected &&
          isInvalidHeight
        ) {
          error["roomHeightInvalid"] = this.getErrorMessageValueShouldBeBetween(
            HEIGHT_LIMITS.heightMin.meters,
            HEIGHT_LIMITS.heightMax.meters
          );
        } else if (sensorMounting !== "wall" && isInvalidHeight) {
          error["roomHeightInvalid"] = this.getErrorMessageValueShouldBeBetween(
            HEIGHT_LIMITS.heightMin.meters,
            HEIGHT_LIMITS.heightMax.meters
          );
        }
      } else {
        // In feet
        const ceilingHeightFeet = group.get("roomCeilingHeightFeet").value;
        const ceilingHeightInches = group.get("roomCeilingHeightInches").value;
        // MIN_VALUE_INCHES <= inches < MAX_VALUE_INCHES validation
        const inchesInvalidError = $localize`:@@inches-invalid:${MIN_VALUE_INCHES}:min: <= inches < ${MAX_VALUE_INCHES}:max:`;
        if (
          ceilingHeightInches < MIN_VALUE_INCHES ||
          ceilingHeightInches >= MAX_VALUE_INCHES
        ) {
          error["ceilingHeightInchesInvalid"] = inchesInvalidError;
        }

        const isInvalidHeight = !isFeetValueInRange(
          ceilingHeightFeet,
          HEIGHT_LIMITS.heightMin.feet,
          HEIGHT_LIMITS.heightMax.feet,
          ceilingHeightInches,
          HEIGHT_LIMITS.heightMin.inches,
          HEIGHT_LIMITS.heightMax.inches
        );
        if (
          sensorMounting === "wall" &&
          !this.standardSensorHeightWasSelected &&
          isInvalidHeight
        ) {
          error["roomHeightInvalid"] = this.getErrorMessageValueShouldBeBetween(
            `${HEIGHT_LIMITS.heightMin.feet}'${HEIGHT_LIMITS.heightMin.inches}"`,
            `${HEIGHT_LIMITS.heightMax.feet}'${HEIGHT_LIMITS.heightMax.inches}"`
          );
        } else if (sensorMounting !== "wall" && isInvalidHeight) {
          error["roomHeightInvalid"] = this.getErrorMessageValueShouldBeBetween(
            `${ROOM_LIMITS[sensorMounting].heightMin.feet}'${ROOM_LIMITS[sensorMounting].heightMin.inches}"`,
            `${ROOM_LIMITS[sensorMounting].heightMax.feet}'${ROOM_LIMITS[sensorMounting].heightMax.inches}"`
          );
        }
      }
      return error;
    };
  }
  SensorMounting = SensorMounting;

  constructor(private fb: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.configurationForm.setControl(
      "roomSizeForm",
      this.fb.group(
        {
          roomXMin: [DEFAULT_ROOM_FORM_VALUE, required],
          roomXMax: [DEFAULT_ROOM_FORM_VALUE, required],
          roomYMin: [DEFAULT_ROOM_FORM_VALUE, required],
          roomYMax: [DEFAULT_ROOM_FORM_VALUE, required],
          roomCeilingHeight: [DEFAULT_ROOM_FORM_VALUE, required],

          roomXMinFeet: [DEFAULT_ROOM_FORM_VALUE, required],
          roomXMinInches: [DEFAULT_ROOM_FORM_VALUE, required],
          roomXMaxFeet: [DEFAULT_ROOM_FORM_VALUE, required],
          roomXMaxInches: [DEFAULT_ROOM_FORM_VALUE, required],
          roomYMinFeet: [DEFAULT_ROOM_FORM_VALUE, required],
          roomYMinInches: [DEFAULT_ROOM_FORM_VALUE, required],
          roomYMaxFeet: [DEFAULT_ROOM_FORM_VALUE, required],
          roomYMaxInches: [DEFAULT_ROOM_FORM_VALUE, required],
          roomCeilingHeightFeet: [DEFAULT_ROOM_FORM_VALUE, required],
          roomCeilingHeightInches: [DEFAULT_ROOM_FORM_VALUE, required],
        },
        { validators: this.roomSizeValidator }
      )
    );

    this.setFormData();
  }

  ngOnChanges(c: SimpleChanges): void {
    if (this.roomSize && ("unit" in c || "config" in c)) {
      this.setFormData();
    }
  }

  setFormData() {
    this.configurationForm.markAsPristine();
    const sensorHeight = +this.config.walabotConfig.sensorHeight.toFixed(
      SENSOR_HEIGHT_MAX_DIGITS_AFTER_DECIMAL_POINT
    );
    if (this.isMetric()) {
      this.roomSize.get("roomXMin").setValue(this.config.walabotConfig.xMin);
      this.roomSize.get("roomXMax").setValue(this.config.walabotConfig.xMax);
      this.roomSize.get("roomYMin").setValue(this.config.walabotConfig.yMin);
      this.roomSize.get("roomYMax").setValue(this.config.walabotConfig.yMax);

      this.roomSize.get("roomCeilingHeight").setValue(sensorHeight);
    } else {
      const xMaxFeet = convertMetersToFeet(this.config.walabotConfig.xMax);
      this.roomSize.get("roomXMaxFeet").setValue(xMaxFeet[0]);
      this.roomSize.get("roomXMaxInches").setValue(xMaxFeet[1]);
      const xMinFeet = convertMetersToFeet(this.config.walabotConfig.xMin);
      this.roomSize.get("roomXMinFeet").setValue(xMinFeet[0]);
      this.roomSize.get("roomXMinInches").setValue(xMinFeet[1]);
      const yMaxFeet = convertMetersToFeet(this.config.walabotConfig.yMax);
      this.roomSize.get("roomYMaxFeet").setValue(yMaxFeet[0]);
      this.roomSize.get("roomYMaxInches").setValue(yMaxFeet[1]);
      const yMinFeet = convertMetersToFeet(this.config.walabotConfig.yMin);
      this.roomSize.get("roomYMinFeet").setValue(yMinFeet[0]);
      this.roomSize.get("roomYMinInches").setValue(yMinFeet[1]);

      const heightFeet = convertMetersToFeet(
        this.config.walabotConfig.sensorHeight
      );
      this.roomSize.get("roomCeilingHeightFeet").setValue(heightFeet[0]);
      this.roomSize.get("roomCeilingHeightInches").setValue(heightFeet[1]);
    }
    if (
      sensorHeight ===
      +ROOM_LIMITS_IN_METERS.standardHeight.toFixed(
        SENSOR_HEIGHT_MAX_DIGITS_AFTER_DECIMAL_POINT
      )
    ) {
      this.standardSensorHeightWasSelected = true;
    }
  }

  filterNegativeValueInches(event: KeyboardEvent, feetName: string) {
    const feetControl = this.configurationForm
      .get("roomSizeForm")
      .get(feetName);
    return filterNegativeValueInches(event, feetControl.value as number);
  }

  filterNegativeValueFeet(event: KeyboardEvent, inchesName: string) {
    const inchesControl = this.configurationForm
      .get("roomSizeForm")
      .get(inchesName);
    return filterNegativeValueFeet(event, inchesControl.value as number);
  }

  validateSubregionSizeAgainstRoomChanged(controlName: string) {
    this.roomChanged.emit(controlName);
  }

  isMetric() {
    return this.unit === MeasurementUnits.Metric;
  }

  getErrorMessageValueShouldBeBetween(min, max) {
    return $localize`:@@room-size-invalid:Value should be between ${min}:min: - ${max}:max:`;
  }

  getErrorMessageLengthXAxisCannotExceed(max) {
    return $localize`:@@room-size-length-x-invalid:Length of X-axis (absolute sum of Xmin and Xmax) cannot exceed ${max}:max:`;
  }

  getErrorMessageLengthYAxisCannotExceed(max) {
    return $localize`:@@room-size-length-y-invalid:Length of Y-axis  (absolute sum of Ymin and Ymax) cannot exceed ${max}:max:`;
  }

  sensorHeightMetersOptionSelected(e: MatAutocompleteSelectedEvent) {
    this.standardSensorHeightWasSelected = !!e.option.value;
  }

  sensorHeightFeetOptionSelected(e: MatAutocompleteSelectedEvent) {
    this.standardSensorHeightWasSelected = !!e.option.value;
    let feet: number, inches: number;
    if (e.option.value) {
      feet = this.sensorHeightInFeet[0];
      inches = this.sensorHeightInFeet[1];
    } else {
      feet = null;
      inches = null;
    }
    this.configurationForm.patchValue({
      roomSizeForm: {
        roomCeilingHeightFeet: feet,
        roomCeilingHeightInches: inches,
      },
    });
  }

  onSensorHeightFeetAutocompleteOpen() {
    this.lastSensorHeightFeetInputTriggerAutocomplete = document.activeElement;
  }

  onSensorHeightMetersKeyPress(e: KeyboardEvent) {
    // Restrict value to have only 1 digit after decimal point
    return /^\d*\.?$/.test((e.target as HTMLInputElement).value);
  }
}

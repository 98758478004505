import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ConfigService } from "../ui.module";
import { Language } from "../config.service";

@Component({
  selector: "app-select-language",
  templateUrl: "./select-language.component.html",
  styleUrls: ["./select-language.component.css"],
})
export class SelectLanguageComponent {
  @Input() showLabel: boolean;
  @Input() emit = false;
  @Output() value = new EventEmitter();
  lang: Language;
  languageList = [
    { value: Language.ENGLISH, name: $localize`:@@english:English` },
    { value: Language.HEBREW, name: $localize`:@@hebrew:Hebrew` },
    {
      value: Language.CHINESE_TRADITIONAL,
      name: $localize`:@@chinese-traditional:Chinese-Traditional`,
    },
    {
      value: Language.CHINESE_SIMPLIFIED,
      name: $localize`:@@chinese-simplified:Chinese-Simplified`,
    },
    { value: Language.GERMAN, name: $localize`:@@german:German` },
    { value: Language.NORWEGIAN, name: $localize`:@@norwegian:Norwegian` },
    { value: Language.SPANISH, name: $localize`:@@spanish:Spanish` },
    { value: Language.FRENCH, name: $localize`:@@french:French` },
    { value: Language.JAPANESE, name: $localize`:@@japanese:Japanese` },
  ];
  constructor(private configService: ConfigService) {
    this.lang = this.configService.getLanguage();
  }

  changeLang() {
    if (this.emit) {
      this.value.emit(this.lang);
    } else {
      this.configService.setLanguage(this.lang);
      window.location.reload();
    }
  }
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeviceConfigActiveTemplate } from '../models/DeviceConfigActiveTemplate';
import type { DeviceConfigTemplate } from '../models/DeviceConfigTemplate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DeviceConfigTemplateAPI {

    /**
     * Get device config template by a filter
     * @param name Filter results with name
     * @param templateIds Filter results by a List of template IDs
     * @returns DeviceConfigTemplate OK
     * @throws ApiError
     */
    public static getDeviceConfigTemplates(
        name?: string,
        templateIds?: Array<string>,
    ): CancelablePromise<Array<DeviceConfigTemplate>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/deviceConfigTemplate',
            query: {
                'name': name,
                'templateIds': templateIds,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get Device Config Template by id
     * @param deviceConfigTemplateId
     * @returns DeviceConfigTemplate OK
     * @throws ApiError
     */
    public static getDeviceConfigTemplateById(
        deviceConfigTemplateId: string,
    ): CancelablePromise<DeviceConfigTemplate> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/deviceConfigTemplate/{deviceConfigTemplateId}',
            path: {
                'deviceConfigTemplateId': deviceConfigTemplateId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Activate Device Config Template for Device
     * @param deviceId
     * @param requestBody
     * @returns DeviceConfigActiveTemplate OK
     * @throws ApiError
     */
    public static setDeviceConfigActiveTemplates(
        deviceId: string,
        requestBody?: {
            deviceConfigTemplateId: string;
        },
    ): CancelablePromise<DeviceConfigActiveTemplate> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/deviceConfigActiveTemplate/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get Device Config Template by Device Id
     * @param deviceId
     * @returns DeviceConfigActiveTemplate OK
     * @throws ApiError
     */
    public static getDeviceConfigActiveTemplate(
        deviceId: string,
    ): CancelablePromise<DeviceConfigActiveTemplate> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/deviceConfigActiveTemplate/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}

<!--The content below is only a placeholder and can be replaced.-->
<div class="root-div"
     [class.logged-in]="isLoggedIn !== null">
  <div *ngIf="isLoggedIn === null" class="full-screen-layout">
    <mat-spinner class="content"></mat-spinner>
  </div>
  <div
          *ngIf="isLoggedIn === true && hasDevices === false && !inAdminMode()"
          class="full-screen-layout"
  >
    <div class="content" style="display: flex; flex-direction: column">
      <span i18n="@@paired-devices-empty-state"
      >It seems you don't have any paired devices</span
      >
      <button
              mat-raised-button
              style="
          margin-top: 2em;
          width: fit-content;
          margin-left: auto;
          margin-right: auto;
        "
              (click)="logout()"
              i18n="@@logout"
      >
        Logout
      </button>
    </div>
  </div>
  <app-login *ngIf="isLoggedIn === false"></app-login>
  <div
          *ngIf="isLoggedIn === true && (hasDevices === true || inAdminMode())"
          class="container"
          [class.is-mobile]="mobileQuery.matches"
  >
    <mat-toolbar *ngIf="!insideIFrame" color="primary" class="toolbar">
      <div style="display: flex; align-items: stretch;">
        <div class="logo">
          <object *ngIf="showLogo" data="../assets/logo.svg"></object>
        </div>
      </div>
      <div class="status">
        <span class="cdk-visually-hidden" #translatedTooltipMessageFalls i18n="@@falls">Falls</span>
        <div class="fall" *ngIf="eventBus.fallCount | async as fallCount"
             [matTooltip]="translatedTooltipMessageFalls.innerHTML"
             matTooltipClass="tooltip"
             matTooltipPosition="below">
          <div class="icon"></div>
          <div class="circle">{{fallCount}}</div>
        </div>
        <span class="cdk-visually-hidden" #translatedTooltipMessageDisconnected i18n="@@disconnections">Disconnections</span>
        <div class="disconnected" *ngIf="eventBus.disconnectedCount | async as disconnectedCount"
             [matTooltip]="translatedTooltipMessageDisconnected.innerHTML"
             matTooltipClass="tooltip"
             matTooltipPosition="below">
          <div class="icon"></div>
          <div class="circle">{{disconnectedCount}}</div>
        </div>
        <div class="monitoring" *ngIf="!((eventBus.fallCount | async ) > 0) && !((eventBus.disconnectedCount | async) > 0)">
          <div class="circle" style="position: relative; margin: 0 5px; width: 40px; height: 40px;">
            <div class="lds-ripple"><div></div><div></div></div>
          </div> <span i18n="@@monitoring">Monitoring</span>
        </div>
      </div>
      <button
              *ngIf="user"
              [matMenuTriggerFor]="menu"
              style="
          display: flex;
          flex-direction: row;
          align-content: center;
          align-items: center;
          background: none;
          border: none;
        "
      >
        <mat-spinner *ngIf="userUpdating; else userImage" class="user-image" [diameter]="30"></mat-spinner>
        <ng-template #userImage>
          <img [src]="userImageUrl" class="user-image" />
        </ng-template>
        <div
                style="
            display: flex;
            flex-direction: column;
            height: 100%;
            margin-left: 0.25em;
          "
        >
          <span class="user-name">{{ getUserNameAndEmail(user).displayName }}</span>
          <span class="user-email">{{ getUserNameAndEmail(user).email }}</span>
        </div>
        <img
                src="../assets/icons/dropdown_arrow.svg"
                style="margin-left: 1em"
        />
        <!-- <button mat-raised-button (click)="logout()">Logout</button> -->
      </button>
      <mat-menu #menu="matMenu" xPosition="before">
        <button mat-menu-item data-cy="logout-btn" (click)="logout()" i18n="@@logout">Logout</button>
        <span class="app-version">v{{ appVersion }}</span><br>
        <span *ngIf="environment.multiRegion" class="cloud-region">{{ latestUserRegion | uppercase }} <ng-container i18n="@@cloud-region">Cloud Region</ng-container></span>
      </mat-menu>
      <div *ngIf="releaseNotesPresented" class="release-notes">
        
        <div class="flex-row">
          <div class="flex-column">
            <span class="title flex-row" i18n="@@release-notes-title">New Improvements Available!</span>
            <div>
              <a target="_blank" href=" https://vayyar.com/care/cssignupform/" i18n="@@subscribe-for-vayyar-care-updates"class="subscribe flex-row">Subscribe for Vayyar Care Updates</a>  
            </div>
          </div>  
  
          <div class="flex-column learn-more">
            <a target="_blank" href="assets/release-notes/{{appVersion}}.pdf" mat-raised-button i18n="@@learn-more">Learn More</a>
          </div>       
        </div>
        <mat-icon class="close" (click)="closeReleaseNotes()">close</mat-icon>
      </div>
    </mat-toolbar>
    <!-- <div *ngIf="currentUser"> -->
    <mat-sidenav-container
            class="sidenav-container"
            [style.marginTop.px]="mobileQuery.matches ? 56 : 0"
    >
      <mat-sidenav *ngIf="!insideIFrame"
                   class="sidenav"
                   #snav
                   [mode]="mobileQuery.matches ? 'over' : 'side'"
                   [fixedInViewport]="mobileQuery.matches"
                   fixedTopGap="56"
                   [opened]="mobileQuery.matches ? 'false' : 'true'"
      >
        <div class="nav-row" routerLinkActive="active-link">
          <nav>
            <a routerLink="{{basePath}}/rooms">
              <svg viewBox="0 0 31 30" width="31" height="30">
                <use xlink:href="../assets/icons/status.svg#icon"></use>
              </svg>
              <span i18n="@@status">Status</span></a
            >
          </nav>
        </div>
        <div class="nav-row" routerLinkActive="active-link">
          <nav>
            <a routerLink="{{basePath}}/history">
              <svg viewBox="0 0 31 30" width="31" height="30">
                <use xlink:href="../assets/icons/history.svg#icon"></use>
              </svg>
              <span i18n="@@history">History</span></a
            >
          </nav>
        </div>
        <ng-container *ngIf="!basePath.startsWith('monitor')">
          <div class="nav-row" routerLinkActive="active-link">
            <nav>
              <a routerLink="{{basePath}}/suites">
                <span class="material-icons admin-dashboard-icon">room_preferences</span>
                <span i18n="@@suites">Suites</span></a
              >
            </nav>
          </div>
          <div class="nav-row" routerLinkActive="active-link">
            <nav>
              <a routerLink="{{basePath}}/caregivers">
                <span class="material-icons admin-dashboard-icon">perm_contact_calendar</span>
                <span i18n="@@contacts">Contacts</span></a
              >
            </nav>
          </div>
          <div class="nav-row" routerLinkActive="active-link">
            <nav>
              <a routerLink="{{basePath}}/settings">
                <svg viewBox="0 0 31 30" width="31" height="30">
                  <use xlink:href="../assets/icons/configuration.svg#icon"></use>
                </svg>
                <span i18n="@@settings">Settings</span></a
              >
            </nav>
          </div>
        </ng-container>
        <div *ngIf="basePath.startsWith('admin') && isAdminDashboardUser
        && userIFrames[activeUserIFrameIndex]?.isAdmin"
             class="nav-row" routerLinkActive="active-link" #admin="routerLinkActive">
          <nav>
            <a routerLink="{{basePath}}/admin" data-cy="admin-dashboard">
              <span class="material-icons admin-dashboard-icon">admin_panel_settings</span>
              <span i18n="@@admin">Admin</span></a
            >
          </nav>
          <div
                  style="display: flex; flex-direction: column; margin-left: 35px"
                  *ngIf="admin.isActive"
          >
            <nav>
              <a
                      class="nav-sub-row"
                      routerLink="{{basePath}}/admin/devices"
                      routerLinkActive="active-link"
                      i18n="@@devices"
              >Devices</a
              >
            </nav>
            <nav>
              <a
                      class="nav-sub-row"
                      routerLink="{{basePath}}/admin/dashboard-users"
                      routerLinkActive="active-link"
                      i18n="@@dashboard-users"
              >Dashboard Users</a
              >
            </nav>
          </div>
        </div>
        <!--<div style="text-align:center">
        <nav>
          <a routerLink="{{basePath}}/raw-data">Raw Data</a>
        </nav>
      </div>-->
      </mat-sidenav>
      <mat-sidenav-content *ngIf="!insideIFrame || !isLoggedIn">
        <ng-container *ngIf="userTabsPresented; else elseBlock">
          <mat-tab-group [preserveContent]="true"
                         [(selectedIndex)]="activeUserIFrameIndex"
                         animationDuration="0"
                         (selectedIndexChange)="onUserTabChanged()">
            <mat-tab *ngFor="let iFrameElement of userIFrames">
              <ng-template mat-tab-label>
                {{iFrameElement.email}}
                <button *ngIf="!iFrameElement.isAdmin" mat-icon-button class="close-tab-icon" (click)="removeUserIFrame(iFrameElement)">
                  <mat-icon>close</mat-icon>
                </button>
              </ng-template>
              <div *ngIf="user.email !== iFrameElement.email && iFrameElement.iFrameReady" style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;">
                <mat-spinner class="content"></mat-spinner>
              </div>
              <div *ngIf="user.email === iFrameElement.email && iFrameElement.iFrameReady" [@routeAnimations]="o && o.activatedRouteData && o.activatedRouteData['title']" style="width: 100%; height: 100%;">
                <router-outlet #o="outlet"></router-outlet>
              </div>
              <iframe #iframe [src]="origin"
                      [attr.data-id]="iFrameElement.uuid"
                      [ngStyle]="{
                        display: iFrameElement.iFrameReady ? 'none' : 'block'
                      }"
                      class="admin-overflow-iframe"></iframe>
            </mat-tab>
            <mat-tab labelClass="add-tab">
              <ng-template mat-tab-label>
                <button mat-icon-button (click)="addUserIFrame($event)">
                  <mat-icon>add</mat-icon>
                </button>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </ng-container>
        <ng-template #elseBlock>
          <div [@routeAnimations]="o && o.activatedRouteData && o.activatedRouteData['title']" style="width: 100%; height: 100%;">
            <router-outlet #o="outlet"></router-outlet>
          </div>
        </ng-template>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>

import { Component, Inject } from "@angular/core";
import { FacilityAnalytics } from "../ui.module";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ResidencyStatus, TopRiskResident } from "../analytics.service";
import { ExportToCsv } from "export-to-csv";

type TopRiskResidentWithIndex = TopRiskResident & {
  index: number;
};
@Component({
  selector: "app-risk-by-apartment",
  templateUrl: "./risk-by-apartment.component.html",
  styleUrls: ["./risk-by-apartment.component.css"],
})
export class RiskByApartmentComponent {
  displayedColumns = ["suite", "score", "link"];
  basePath: string;
  topRiskResidents: Array<TopRiskResidentWithIndex>;
  topRiskResidentsByResidencyStatus: Array<TopRiskResidentWithIndex>;
  keys = ["Apartment", "Risk Score", "Residency Status"];
  csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalseparator: ".",
    showLabels: true,
    headers: this.keys,
    showTitle: false,
    useBom: true,
    removeNewLines: true,
    filename: "fall-risk-per-apartment",
  };
  residencyStatus: ResidencyStatus = "on_site";

  constructor(
    public dialog: MatDialogRef<RiskByApartmentComponent>,
    @Inject(MAT_DIALOG_DATA)
    public analytics: FacilityAnalytics
  ) {
    this.topRiskResidents = (analytics?.topRiskResidents ?? []).map(
      (item, index) => ({ ...item, index })
    );
    this.setResidencyStatus(this.residencyStatus);
    this.basePath = localStorage.getItem("basePath");
  }

  getRiskLevelColor(level_id: number) {
    return (
      "#" + this.analytics.dict.risk_level.find((l) => l.id === level_id)?.color
    );
  }

  close() {
    this.dialog.close();
  }

  downloadCSV() {
    const csvData = this.topRiskResidentsByResidencyStatus.map((e) => ({
      Apartment: e.suite_name,
      "Risk Score": e.risk_score,
      "Residency Status": {
        on_site: $localize`:@@on-site:On Site`,
        on_leave: $localize`:@@on-leave:On Leave`,
      }[e.residency_status],
    }));
    const csvExporter = new ExportToCsv(this.csvOptions);
    /**
     * Fixme: workaround for https://github.com/Vayyar/VayyarHomeCloud/issues/2349
     * Wait for https://github.com/alexcaza/export-to-csv/issues/10 to be fixed
     */

    // eslint-disable-next-line
    const _getBody = csvExporter["_getBody"].bind(csvExporter);
    csvExporter["_getBody"] = function () {
      try {
        // eslint-disable-next-line
        _getBody();
      } catch (e) {
        console.warn(e);
      }
    };
    csvExporter.generateCsv(csvData);
  }

  setResidencyStatus(status: ResidencyStatus) {
    this.residencyStatus = status;
    this.topRiskResidentsByResidencyStatus = this.topRiskResidents.filter(
      (v) => v.residency_status === this.residencyStatus
    );
  }
}

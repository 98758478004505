import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "secondsToDuration",
})
export class SecondsToDurationPipe implements PipeTransform {
  transform(seconds: number): string {
    const minutes = Math.round(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (hours === 0) {
      return $localize`:@@duration-in-minutes:${remainingMinutes}:duration: Minutes`;
    } else {
      return $localize`:@@duration-in-hours-and-minutes:${hours}:hours: Hours ${remainingMinutes}:minutes: Minutes`;
    }
  }
}

<div
  #rootContainer
  class="container mat-elevation-z4"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <div
    mat-dialog-title
    cdkDragHandle
  >
    <span style="line-height: 72px; color: white"
      >{{ roomName }} - {{ deviceName }}</span
    >
    <span
      style="
        line-height: 72px;
        float: right;
        color: white;
        margin-right: 1em;
        cursor: pointer;
      "
      (click)="close()"
      >X</span
    >
  </div>

  <mat-spinner *ngIf="loading" style="margin: auto"></mat-spinner>
  <div class="tracker-container">
    <app-preview2d *ngIf="this.config"
            [isMetric]="isMetric.bind(this)"
            [getSettings]="getSettings.bind(this)"
            [config]="$any(config.walabotConfig)"
            [roomSize]="$any(config.walabotConfig)"
            [targets]="targets"
            [subRegions]="$any(config.walabotConfig.trackerSubRegions)"
            [getRoomSizeFromForm]="getRoomSizeFromForm.bind(this)"
            [getSubregionsFromForm]="getSubregionsFromForm.bind(this)"></app-preview2d>
  </div>
  <div class="legend-container">
    <div><div class="legend" [ngStyle]="{'--color': monitoringAreaColor}"></div><span>Monitoring area</span></div>
    <div style="margin-top: 5px;"><div class="legend" [ngStyle]="{'--color': subRegionColor}"></div><span>Sub-regions</span></div>
    <div style="margin-top: 5px;"><div class="legend" [ngStyle]="{'--color': sensorColor}"></div><span>Sensor</span></div>
    <ng-container *ngIf="isSensorMountingSpecified()">
      <br>
      <div>
        <b>Sensor mounting:</b> {{formatSensorMounting()}}
      </div>
    </ng-container>
  </div>
</div>
